import { useState, useEffect, memo, useMemo } from "react";
import Container from "@material-ui/core/Container";

import Alert from "../../../components/Alert";
import Loader from "../../../components/Loader";
import TableUI from "../../../components/TablesUI";
import apiCall from "../../../services/apiCall";

function ShowAllEvv() {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ isOpen: false, message: "" });

  const memoizedTable = useMemo(() => renderTable(data.table), [data.table]);

  async function getdataTable(url) {
    const data = await getData(url);

    if (data) {
      setData(data);
    }
  }

  async function getData(url, verb, payload) {
    setIsLoading(true);
    const response = await apiCall(url, verb, payload);
    setIsLoading(false);

    if (response instanceof Error) {
      setAlert({ isOpen: true, message: response.message });
      return null;
    } else {
      setAlert({ isOpen: false, message: "" });
      return response;
    }
  }

  async function initData() {
    const url = `/prod/evaluation/count`;

    getdataTable(url);
  }

  useEffect(() => {
    initData();
  }, []);

  //  //////// EVENT HANDLERS //////////

  function handleCloseAlert() {
    setAlert({ isOpen: false, message: "" });
  }

  //  ////////// RENDERERS /////////////

  function renderTable(table) {
    if (table == null) {
      return null;
    }
    const columns = [
      {
        name: "fecha",
        label: "Fecha Inicio Semana",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "evaluaciones",
        label: "Evaluaciones en la semana",
        options: {
          filter: true,
          sort: true,
        },
      },
    ];

    const { data, config } = table;

    return <TableUI data={data} columns={columns} config={config} />;
  }

  return (
    <Container maxWidth="xl" disableGutters>
      <Loader isLoading={isLoading} />
      <Alert
        isOpen={alert.isOpen}
        message={alert.message}
        onClose={handleCloseAlert}
      />
      {memoizedTable}
    </Container>
  );
}

export default memo(ShowAllEvv);
