import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Alert from "../../../components/Alert";
import Loader from "../../../components/Loader";
import apiCall from "../../../services/apiCall";

function OneEvaluationMake() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ isOpen: false, message: "" });

  useEffect(() => {
    url();
  }, [location]);

  async function getData(url, verb, payload) {
    setIsLoading(true);
    const response = await apiCall(url, verb, payload);
    setIsLoading(false);

    if (response instanceof Error) {
      setAlert({ isOpen: true, message: response.message });
      return null;
    } else {
      setAlert({ isOpen: false, message: "" });
      return response;
    }
  }

  async function url() {
    const downloadUrl = `/prod/evaluation/image?ID=${location.state.detail.ID}`;
    const download = await getData(downloadUrl);

    console.log(download);

    if (download) {
      window.location.replace(download);
    }
  }

  return (
    <div maxWidth="lg" disableGutters>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default OneEvaluationMake;
