import { useState, useEffect, memo } from "react";

import Container from "@material-ui/core/Container";

import apiCall from "../../services/apiCall";
import Alert from "../../components/Alert";
import Loader from "../../components/Loader";

function AfterLogin() {
  const emailD = localStorage.getItem("Email");
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ isOpen: false, message: "" });
  const [dataRole, setData] = useState([]);

  async function getData(url) {
    setIsLoading(true);
    const response = await apiCall(url);
    setIsLoading(false);

    if (response instanceof Error) {
      setAlert({ isOpen: true, message: response.message });
      return null;
    } else {
      setAlert({ isOpen: false, message: "" });
      return response;
    }
  }

  async function getFilters() {
    const dataRole = await getData(`/prod/members/get-role?email=${emailD}`);
    if (dataRole) {
      setData(dataRole);
    }

    return null;
  }

  useEffect(() => {
    getFilters();
  }, []);

  //  //////// EVENT HANDLERS //////////

  function handleCloseAlert() {
    setAlert({ isOpen: false, message: "" });
  }

  //  ////////// RENDERERS /////////////
  function renderRedirect() {
    if (dataRole == null) {
      return null;
    }
    const url = "https://teamlifes.com";
    // const url = "https://dev.d1lv46gxpqhbh7.amplifyapp.com";
    // const url = "http://localhost:3000";

    const { nombre, apellido, rango } = dataRole;
    localStorage.setItem("nombre", nombre);
    localStorage.setItem("apellido", apellido);
    localStorage.setItem("phone", rango);

    const role = dataRole["tipo"];

    if (role == "admin") {
      localStorage.setItem("role", "admin");
      window.location.href = url + "/home-app";
    } else if (role == "capitan") {
      localStorage.setItem("role", "capitan");
      window.location.href = url + "/home-app";
    } else if (role == "supervisor") {
      localStorage.setItem("role", "supervisor");
      window.location.href = url + "/home-app";
    } else if (role == "ambos") {
      localStorage.setItem("role", "ambos");
      window.location.href = url + "/home-app";
    } else if (role == "inactivo") {
      window.location.href = url + "/none";
    }
    return null;
  }
  return (
    <Container maxWidth="xl" disableGutters>
      <Loader isLoading={isLoading} />
      <Alert
        isOpen={alert.isOpen}
        message={alert.message}
        onClose={handleCloseAlert}
      />
      {renderRedirect()}
    </Container>
  );
}

export default memo(AfterLogin);
