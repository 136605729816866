import { useState } from "react";
import { useHistory } from "react-router-dom";

import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";

import Loader from "../../../components/Loader";
import apiCall from "../../../services/apiCall";

import styles from "./styles.module.scss";

function NewUser() {
  let history = useHistory();

  const [user, setUser] = useState({
    name: "",
    apellido: "",
    rango: "",
    tipo: "",
    email: "",
    alias: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    const result = await apiCall(`/prod/members/new`, "post", user);
    setIsLoading(false);

    if (result.statusCode === 200) {
      history.push("/users/show-all");
    } else {
      console.log(result);
      history.push("/home");
    }
    return null;
  }

  function handleChangeUser(event) {
    const { name, value } = event.target;

    setUser({
      ...user,
      [name]: value,
    });
  }

  return (
    <>
      <Loader isLoading={isLoading} />

      <Container maxWidth="md" className={styles.PaddingUp}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <Typography variant="h6" className={styles.title}>
            Datos del Supervisor o Capitan
          </Typography>
          <div className={styles.middle}>
            <label htmlFor="name" className={styles.Label}>
              Nombre*
            </label>
            <input
              type="text"
              className={styles.Input}
              name="name"
              required
              value={user.name}
              onChange={handleChangeUser}
              autoComplete="off"
            />
          </div>
          <div className={styles.middle}>
            <label htmlFor="apellido" className={styles.Label}>
              Apellido*
            </label>
            <input
              type="text"
              className={styles.Input}
              name="apellido"
              value={user.apellido}
              onChange={handleChangeUser}
              autoComplete="off"
            />
          </div>
          <div className={styles.middle}>
            <label htmlFor="tipo" className={styles.Label}>
              Tipo*
            </label>
            <select
              name="tipo"
              id="tipo"
              className={styles.Input}
              value={user.tipo}
              onChange={handleChangeUser}
              required
            >
              <option value="">SELECCIONA UN TIPO</option>
              <option value="capitan">CAPITAN</option>
              <option value="supervisor">SUPERVISOR</option>
              <option value="ambos">AMBOS</option>
            </select>
          </div>
          <div className={styles.middle}>
            <label htmlFor="phone" className={styles.Label}>
              Celular*
            </label>
            <input
              type="phone"
              className={styles.Input}
              name="rango"
              required
              value={user.rango}
              onChange={handleChangeUser}
              autoComplete="off"
            />
          </div>
          <div className={styles.middle}>
            <label htmlFor="email" className={styles.Label}>
              Correo*
            </label>
            <input
              type="text"
              className={styles.Input}
              name="email"
              required
              value={user.email}
              onChange={handleChangeUser}
              autoComplete="off"
            />
          </div>
          <div className={styles.middle}>
            <label htmlFor="label" className={styles.Label}>
              Alias (opcional)
            </label>
            <input
              type="text"
              className={styles.Input}
              name="alias"
              value={user.alias}
              onChange={handleChangeUser}
              autoComplete="off"
              placeholder="ej. Luchito del Sur"
            />
          </div>
          <Typography className={styles.title}></Typography>
          <button type="submit" className={styles.button}>
            Enviar
          </button>
        </form>
      </Container>
    </>
  );
}

export default NewUser;
