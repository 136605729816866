import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";

import Container from "@material-ui/core/Container";

import Loader from "../../../components/Loader";
import apiCall from "../../../services/apiCall";
import avatar from "../../../assets/images/avatar.png";

import styles from "./styles.module.scss";

function AddMaratonistas() {
  let history = useHistory();
  const [data, setData] = useState({});
  const [maratonistas, setMaratonistas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tipos, setTipos] = useState([]);
  const [alert, setAlert] = useState({ isOpen: false, message: "" });
  const location = useLocation();
  const [equipo, setEquipo] = useState({
    name1: "",
    peso1: "",
    name2: "",
    peso2: "",
    name3: "",
    peso3: "",
    name4: "",
    peso4: "",
    name5: "",
    peso5: "",
    name6: "",
    peso6: "",
    name7: "",
    peso7: "",
    name8: "",
    peso8: "",
    name9: "",
    peso9: "",
  });

  useEffect(() => {
    setData(location.state.detail);
  }, [location]);

  // async function getData(url, verb, payload) {
  //   setIsLoading(true);
  //   const response = await apiCall(url, verb, payload);
  //   setIsLoading(false);

  //   if (response instanceof Error) {
  //     setAlert({ isOpen: true, message: response.message });
  //     return null;
  //   } else {
  //     setAlert({ isOpen: false, message: "" });
  //     return response;
  //   }
  // }

  // async function makeFilters() {
  //   const Email = localStorage.getItem("Email");
  //   const IdMaraton = location.state.detail.ID;
  //   console.log(IdMaraton);
  //   const url = `/prod/maraton/show-all-player?superior=${Email}&ID=${IdMaraton}`;

  //   const tipos = await getData(url);

  //   if (tipos) {
  //     setTipos(tipos);
  //   }
  //   return null;
  // }

  // useEffect(() => {
  //   makeFilters();
  // }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    const Email = localStorage.getItem("Email");

    const test = {
      ID: data.ID,
      superior: Email,
      equipo: equipo,
    };

    console.log(test);
    const result = await apiCall(
      `/prod/maraton/add-maratonistas`,
      "post",
      test
    );
    setIsLoading(false);

    if (result.statusCode === 200) {
      history.push("/maraton/mis-maraton");
    } else {
      console.log(result);
    }
    return null;
  }

  function handleChangeEvaluation(event, args) {
    let { name, value } = event.target;

    if (name === "sexo") {
      value = args;
    }

    setEquipo({
      ...equipo,
      [name]: value,
    });
  }
  ////////////////RENDER//////////////

  function renderPlayer(num) {
    const name = "name" + num;
    const peso = "peso" + num;
    return (
      <div className={styles.RowPlayer}>
        <div className={styles.MiddleData}>
          <label htmlFor="name" className={styles.LabelInput}>
            Jugador {num}*
          </label>
          <input
            type="text"
            className={styles.Input}
            name={name}
            // required
            autoComplete="off"
            onChange={handleChangeEvaluation}
          />
          <label htmlFor="name" className={styles.LabelInput}>
            Peso*
          </label>
          <input
            type="text"
            className={styles.Input}
            name={peso}
            // required
            autoComplete="off"
            onChange={handleChangeEvaluation}
          />
        </div>
        <div className={styles.MiddleFoto}>
          <img width="50%" src={avatar} alt="Logo" />
          <input type="file" className={styles.TextPlayer} />
        </div>
      </div>
    );
  }

  return (
    <Container maxWidth="xs" className={styles.PaddingUp}>
      <Loader isLoading={isLoading} />
      <form onSubmit={handleSubmit} className={styles.form}>
        <Typography variant="h6" className={styles.title}>
          Agregar Jugadores a la Maratón del {data.fecha}
        </Typography>
        {renderPlayer(1)}
        <Typography className={styles.title}></Typography>
        {renderPlayer(2)}
        <Typography className={styles.title}></Typography>
        {renderPlayer(3)}
        <Typography className={styles.title}></Typography>
        {renderPlayer(4)}
        <Typography className={styles.title}></Typography>
        {renderPlayer(5)}
        <Typography className={styles.title}></Typography>
        {renderPlayer(6)}
        <Typography className={styles.title}></Typography>
        {renderPlayer(7)}
        <Typography className={styles.title}></Typography>
        {renderPlayer(8)}
        <Typography className={styles.title}></Typography>
        {renderPlayer(9)}
        <Typography className={styles.title}></Typography>
        <Typography className={styles.title}></Typography>
        <button type="submit" className={styles.button}>
          AGREGAR EQUIPO
        </button>
        <Typography className={styles.title}></Typography>
      </form>
    </Container>
  );
}

export default AddMaratonistas;
