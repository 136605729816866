import { memo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import makeStyles from "@material-ui/core/styles/makeStyles";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  config: PropTypes.object,
};
const defaultProps = {
  columns: [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "capital",
      label: "Capital",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          let cellProps = {};
          if (value > 33) {
            cellProps["style"] = {
              background: "red",
            };
          }
          return (
            <div {...cellProps} onClick={() => alert(value)}>
              {value}
            </div>
          );
        },
      },
    },
  ],
  data: [
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "John Walsh", capital: 23 },
    { name: "John Walsh", capital: 23 },
    { name: "John Walsh", capital: 23 },
    { name: "John Walsh", capital: 23 },
    { name: "John Walsh", capital: 23 },
    { name: "John Walsh", capital: 23 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "John Walsh", capital: 23 },
    { name: "Joe James", capital: 12 },
    { name: "100", capital: 100 },
  ],
  config: {
    filter: true,
    filterType: "checkbox", //dropdown
    viewColumns: true,
    download: true,
    print: true,
    pagination: true,
    rowsPerPage: 15,
    search: true,
    padding: "medium",
    colorRow: "#ffebee",
    colorTable: "#e57373",
    colorTitle: "White",
    selectableRows: "none",
    titleTable: "Titulo de la Finta",
  },
};
const useStyles = makeStyles((theme) => ({
  Table: {
    width: "100%",
    backgroundColor: "#272121", //Titulo y Paginacion si no hay footer y comandos
    borderRadius: 0,
  },
}));
function TableUI({ data, columns, config, className }) {
  const classes = useStyles();
  const options = {
    setTableProps: () => {
      return {
        size: config["padding"] ? config["padding"] : "medium", //small es la otra mas junta
      };
    },
    ...config,
  };

  const components = {
    ExpandButton: function (props) {
      if (props.isHeaderCell) {
        return null;
      }

      if (props.iconClass.includes("expanded")) {
        return (
          <KeyboardArrowDownIcon style={{ color: "#FFF", margin: "auto" }} />
        );
      }

      return (
        <KeyboardArrowRightIcon style={{ color: "#FFF", margin: "auto" }} />
      );
    },
  };

  return (
    <MUIDataTable
      className={classNames(classes.Table, className)}
      title={config["titleTable"]}
      data={data}
      columns={columns}
      options={options}
      components={components}
    />
  );
}
TableUI.propTypes = propTypes;
TableUI.defaultProps = defaultProps;
export default memo(TableUI);
