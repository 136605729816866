import PropTypes from "prop-types";

import Select from "@material-ui/core/Select";

import MultiSelectChip from "./MultiSelectChip";

import { Delete } from "@material-ui/icons";

import {
  Checkbox,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
} from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  customCheckboxStyles,
  customSearchSelectStyles,
  customSelectStyles,
} from "../Customs/customStyles";

import styles from "../styles.module.scss";

const propTypes = {
  filter: PropTypes.object.isRequired,
  appliedFilter: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
};

function CustomDynamicFilterSelect({ filter, appliedFilter, onFilterChange }) {
  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ///////// PRIVATE METHODS ////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  const config = filter.config;

  const useStyles = makeStyles((theme) => ({
    select: {
      color: "#FFF",
    },
  }));

  const customStyles = useStyles();

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  //////// EVENT HANDLERS //////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function handleChangeSelect(event) {
    const { value } = event.target;
    onFilterChange(value);
  }

  function handleChangeSearchableSelect(event, newValue) {
    if (newValue) {
      const { value } = newValue;
      onFilterChange(value);
    } else {
      onFilterChange(null);
    }
  }

  function handleMultiSelectValueClick(valueToRemove) {
    const values = appliedFilter.value;
    const filteredValues = values.filter((value) => value != valueToRemove);

    onFilterChange(filteredValues);
  }

  function handleClearMultiSelectValues() {
    onFilterChange([]);
  }

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ////////// RENDERERS /////////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function renderSelectOptions(options, selectedOption) {
    const optionsCopy = [...options];
    const isMulti = config?.isMulti ?? false;

    const SelectOptionCheckbox = customCheckboxStyles(Checkbox, "black");

    function renderMultiSelectCheckbox(value) {
      if (!isMulti) {
        return null;
      }
      return (
        <SelectOptionCheckbox checked={selectedOption.indexOf(value) > -1} />
      );
    }

    const mappedOptions = optionsCopy.map(({ label, value }, index) => (
      //need unique id
      <MenuItem key={index} value={value}>
        {renderMultiSelectCheckbox(value)}
        {label}
      </MenuItem>
    ));

    return mappedOptions;
  }

  function renderMultiSelectValue(selected) {
    return (
      <div className={styles.MultiSelectValueContainer}>
        {selected.map((value, index) => (
          //need unique id
          <MultiSelectChip
            key={index}
            value={value}
            onHandleMultiSelectValueClick={handleMultiSelectValueClick}
          />
        ))}
      </div>
    );
  }

  function renderClearAllValues() {
    const isMulti = config?.isMulti ?? false;

    if (!isMulti || appliedFilter?.value?.length > 0 === false) {
      return null;
    }

    return (
      <Delete
        className={styles.MultiSelectDeleteAllValuesIcon}
        onClick={handleClearMultiSelectValues}
      />
    );
  }

  function renderSearchableSelect(options, selectedOption) {
    const CustomFormControl = customSearchSelectStyles(Autocomplete, config);

    const value = options.find((option) => option.value === selectedOption);

    return (
      <CustomFormControl
        value={value}
        onChange={handleChangeSearchableSelect}
        options={options}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField {...params} label={filter.label} variant="outlined" />
        )}
      />
    );
  }

  function renderFilterSelect() {
    const CustomFormControl = customSelectStyles(FormControl, config);

    let selectedOption;

    const isMulti = config?.isMulti ?? false;

    const isSearchable = config?.isSearchable ?? false;

    if (appliedFilter != null) {
      selectedOption = appliedFilter.value;
    } else if (isMulti) {
      selectedOption = [];
    } else {
      selectedOption = "";
    }

    let selectProps = {};

    if (isSearchable) {
      return renderSearchableSelect(filter.options, selectedOption);
    }

    if (isMulti) {
      selectProps["renderValue"] = renderMultiSelectValue;
    }

    return (
      <CustomFormControl variant="outlined">
        <InputLabel
          style={{
            color: "white",
            backgroundColor: "black",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          {filter.label}
        </InputLabel>
        <Select
          className={customStyles.select}
          multiple={isMulti}
          value={selectedOption}
          options={filter.options}
          onChange={handleChangeSelect}
          labelId="label"
          {...selectProps}
        >
          {renderSelectOptions(filter.options, selectedOption)}
        </Select>

        {renderClearAllValues()}
      </CustomFormControl>
    );
  }

  return renderFilterSelect();
}

CustomDynamicFilterSelect.propTypes = propTypes;
export default CustomDynamicFilterSelect;
