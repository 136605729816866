import PropTypes from "prop-types";

import { Chip, makeStyles } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const propTypes = {
  value: PropTypes.string.isRequired,
  onHandleMultiSelectValueClick: PropTypes.func.isRequired,
};

function CustomDynamicFiltersMultiSelectChip({
  value,
  onHandleMultiSelectValueClick,
}) {
  const useStyles = makeStyles((theme) => ({
    chip: {
      margin: 2,
      background: "rgb(243, 243, 243)",
    },
    deleteIcon: {
      color: "#000",
    },
  }));

  const styles = useStyles();

  function handleDelete() {
    onHandleMultiSelectValueClick(value);
  }

  function handleDeleteIconOnMouseDown(event) {
    event.stopPropagation();
  }

  return (
    <Chip
      key={value}
      label={value}
      className={styles.chip}
      deleteIcon={
        <Delete
          className={styles.deleteIcon}
          onMouseDown={handleDeleteIconOnMouseDown}
        />
      }
      onDelete={handleDelete}
    />
  );
}

CustomDynamicFiltersMultiSelectChip.propTypes = propTypes;
export default CustomDynamicFiltersMultiSelectChip;
