import { useState, useEffect, memo, useMemo } from "react";
import { useHistory } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Loader from "../../../components/Loader";
import apiCall from "../../../services/apiCall";
import Alert from "../../../components/Alert";
import styles from "./styles.module.scss";
import { colors, Typography } from "@material-ui/core";
import Select from "react-select";
import makeAnimated from "react-select/animated";

function NewReto21() {
  let history = useHistory();
  const [fechaR, setFecha] = useState("");
  const [supervisores, setSupervisores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tipos, setTipos] = useState([]);
  const [alert, setAlert] = useState({ isOpen: false, message: "" });
  const today = new Date().toLocaleDateString();

  async function getData(url, verb, payload) {
    setIsLoading(true);
    const response = await apiCall(url, verb, payload);
    setIsLoading(false);

    if (response instanceof Error) {
      setAlert({ isOpen: true, message: response.message });
      return null;
    } else {
      setAlert({ isOpen: false, message: "" });
      return response;
    }
  }

  async function makeFilters() {
    const url = `/prod/members/cap-sup?tipo=supervisor,ambos,admin`;

    const tipos = await getData(url);

    if (tipos) {
      setTipos(tipos);
    }
  }

  useEffect(() => {
    makeFilters();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    const test = {
      fecha: fechaR,
      supervisores: supervisores,
      estado: "Activo",
    };

    console.log(test);

    const result = await apiCall(`/prod/reto21/new`, "post", test);
    setIsLoading(false);

    if (result.statusCode === 200) {
      history.push("/reto21/show-all");
    } else {
      console.log(result);
    }
  }

  function handleChangeFecha(event) {
    event.preventDefault();
    console.log(event.target.value);
    setFecha(event.target.value);
  }

  ////////////////RENDER//////////////
  function renderFilter() {
    if (tipos == null) {
      return null;
    }

    const mappedTipos = tipos.map((tip) => {
      return { value: tip.email, label: tip.nombre + " " + tip.apellido };
    });

    const animatedComponents = makeAnimated();

    return (
      <div className={styles.middle}>
        <label for="tipo" className={styles.Label}>
          Seleccione Supervisores*
        </label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          options={mappedTipos}
          onChange={setSupervisores}
        />
      </div>
    );
  }

  return (
    <Container maxWidth="xs" className={styles.PaddingUp}>
      <Loader isLoading={isLoading} />
      <form onSubmit={handleSubmit} className={styles.form}>
        <Typography variant="h6" className={styles.title}>
          Selecciona una fecha y agrega supervisores para crear el reto21
        </Typography>
        <Typography variant="subtittle" className={styles.title}>
          Se muestran aquellos supervisores que tienen el rol de supervisor,
          ambos y admin.
        </Typography>
        {/* FECHA */}
        <div className={styles.middle}>
          <label for="fecha" className={styles.Label}>
            Fecha Inicio*
          </label>
          <input
            type="date"
            className={styles.Input}
            name="fecha"
            // disabled="false"
            onChange={handleChangeFecha}
          />
        </div>
        {/* MES */}
        {renderFilter()}
        <Typography className={styles.title}></Typography>
        <button type="submit" className={styles.button}>
          Enviar
        </button>
      </form>
    </Container>
  );
}

export default NewReto21;
