import { useState } from "react";
import { useHistory } from "react-router-dom";

import { Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";

import Loader from "../../../components/Loader"; // la barrita de carga
import apiCall from "../../../services/apiCall";

import { faFemale } from "@fortawesome/free-solid-svg-icons";
import { faMale } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./styles.module.scss";

function NewEvaluation() {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [evaluation, setEvaluation] = useState({
    name: "",
    apellido: "",
    ciudad: "",
    peso: "",
    altura: "",
    edad: "",
    sexo: "",
    numero: "",
    fechac: "",
    cmcuello: "",
    cmcintura: "",
    cmcadera: "",
    superior: localStorage.getItem("Email"),
    superiorName: localStorage.getItem("nombre"),
    superiorApellido: localStorage.getItem("apellido"),
    superiorNumber: localStorage.getItem("phone"),
    referido: "",
    IDbase: "1",
    cliente: "No",
  });

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    const result = await apiCall(`/prod/evaluation/new`, "post", evaluation);
    setIsLoading(false);

    if (result?.statusCode === 200) {
      history.push("/evaluation/show-all");
    } else {
      console.log(result);
    }
  }

  function handleChangeEvaluation(event, args) {
    let { name, value } = event.target;

    if (name === "sexo") {
      value = args;
    }

    setEvaluation({
      ...evaluation,
      [name]: value,
    });
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <Container maxWidth="md" className={styles.PaddingUp}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <Typography variant="h6" className={styles.title}>
            Datos Personales
          </Typography>
          <div className={styles.middle}>
            <label htmlFor="name" className={styles.Label}>
              Nombre*
            </label>
            <input
              type="text"
              className={styles.Input}
              name="name"
              required
              onChange={handleChangeEvaluation}
              value={evaluation.name}
              placeholder="Harvy"
              autoComplete="off"
            />
          </div>
          <div className={styles.middle}>
            <label htmlFor="apellido" className={styles.Label}>
              Apellido
            </label>
            <input
              type="text"
              className={styles.Input}
              name="apellido"
              onChange={handleChangeEvaluation}
              value={evaluation.apellido}
              placeholder="Specter"
              autoComplete="off"
            />
          </div>
          <div className={styles.middle}>
            <label htmlFor="ciudad" className={styles.Label}>
              Ciudad*
            </label>
            <input
              type="text"
              className={styles.Input}
              name="ciudad"
              onChange={handleChangeEvaluation}
              value={evaluation.ciudad}
              autoComplete="off"
            />
          </div>
          <div className={styles.middle}>
            <label htmlFor="edad" className={styles.Label}>
              Edad*
            </label>
            <input
              type="text"
              className={styles.Input}
              name="edad"
              required
              onChange={handleChangeEvaluation}
              value={evaluation.edad}
              autoComplete="off"
            />
          </div>

          <div className={styles.middle}>
            <label htmlFor="fechac" className={styles.Label}>
              Fecha Cumpleaños (dia-mes-año)
            </label>
            <input
              type="date"
              className={styles.Input}
              name="fechac"
              onChange={handleChangeEvaluation}
              value={evaluation.fechac}
              placeholder="dd-mm-yyyy"
            />
          </div>

          <div className={styles.middle}>
            <label htmlFor="numero" className={styles.Label}>
              N° Celular*
            </label>
            <input
              type="text"
              className={styles.Input}
              name="numero"
              onChange={handleChangeEvaluation}
              value={evaluation.numero}
            />
          </div>

          <div className={styles.middle}>
            <label htmlFor="sexo" className={styles.Label}>
              Sexo
            </label>
            <div className={styles.Sexo}>
              <input
                name="sexo"
                style={{ zoom: "2" }}
                type="radio"
                onChange={(event) => handleChangeEvaluation(event, "femenino")}
                required
              />
              <FontAwesomeIcon icon={faFemale} size="2x" />

              <input
                name="sexo"
                style={{ zoom: "2" }}
                type="radio"
                onChange={(event) => handleChangeEvaluation(event, "masculino")}
              />
              <FontAwesomeIcon icon={faMale} size="2x" />
            </div>
          </div>
          <Typography variant="h6" className={styles.title}>
            Métricas Personales
          </Typography>
          <div className={styles.dates}>
            <div className={styles.middlethird}>
              <label htmlFor="peso" className={styles.Label}>
                Peso(kg)*
              </label>
              <input
                type="text"
                className={styles.Input}
                name="peso"
                required
                onChange={handleChangeEvaluation}
                value={evaluation.peso}
                placeholder="69.8"
                autoComplete="off"
              />
            </div>

            <div className={styles.middlethird}>
              <label htmlFor="altura" className={styles.Label}>
                Altura(cm)*
              </label>
              <input
                type="text"
                className={styles.Input}
                name="altura"
                required
                onChange={handleChangeEvaluation}
                value={evaluation.altura}
                placeholder="169"
                autoComplete="off"
              />
            </div>

            <div className={styles.middlethird}>
              <label htmlFor="cmcuello" className={styles.Label}>
                Cuello(cm)
              </label>
              <input
                type="text"
                className={styles.Input}
                name="cmcuello"
                onChange={handleChangeEvaluation}
                value={evaluation.cmcuello}
                placeholder="25"
                autoComplete="off"
              />
            </div>

            <div className={styles.middlethird}>
              <label htmlFor="cmcintura" className={styles.Label}>
                Cintura(cm)
              </label>
              <input
                type="text"
                className={styles.Input}
                name="cmcintura"
                onChange={handleChangeEvaluation}
                value={evaluation.cmcintura}
                placeholder="98"
                autoComplete="off"
              />
            </div>

            <div className={styles.middlethird}>
              <label htmlFor="cmcadera" className={styles.Label}>
                Cadera(cm)
              </label>
              <input
                type="text"
                className={styles.Input}
                name="cmcadera"
                onChange={handleChangeEvaluation}
                value={evaluation.cmcadera}
                placeholder="65"
                autoComplete="off"
              />
            </div>
          </div>
          <Typography className={styles.title}></Typography>
          <div className={styles.middle}>
            <label htmlFor="referido" className={styles.Label}>
              Referido Por
            </label>
            <input
              type="text"
              className={styles.Input}
              name="referido"
              onChange={handleChangeEvaluation}
              value={evaluation.referido}
              autoComplete="off"
            />
          </div>
          <button type="submit" className={styles.button}>
            Evaluar
          </button>
        </form>
      </Container>
    </>
  );
}

export default NewEvaluation;
