import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import MaterialIcon from "material-icons-react";

import Loader from "../../../components/Loader";
import apiCall from "../../../services/apiCall";

import styles from "./styles.module.scss";

const Seguimiento = (props) => {
  let history = useHistory();
  const location = useLocation();
  const [data, setData] = useState({});
  const [peso, setPeso] = useState("");
  const [cmcuello, setCmcuello] = useState("");
  const [cmcintura, setCmcintura] = useState("");
  const [cmcadera, setCmcadera] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    const email = localStorage.getItem("Email");

    setIsLoading(true);

    const test = {
      name: data.nombre,
      ciudad: data.ciudad,
      altura: data.altura,
      edad: data.edad,
      sexo: data.sexo,
      numero: data.telefono,
      apellido: data.apellido,
      fechac: data.fechac,
      peso: peso,
      cmcuello: cmcuello,
      cmcintura: cmcintura,
      cmcadera: cmcadera,
      superior: email,
      referido: data.referido,
      IDbase: data.ID,
      cliente: data.cliente,
      superiorName: localStorage.getItem("nombre"),
      superiorApellido: localStorage.getItem("apellido"),
      superiorNumber: localStorage.getItem("phone"),
    };
    console.log(test);

    const result = await apiCall(`/prod/evaluation/new`, "post", test);
    setIsLoading(false);
    if (result.statusCode === 200) {
      history.push("/evaluation/show-all");
    } else {
      console.log(result);
    }
    return null;
  }

  function handleChangePeso(event) {
    event.preventDefault();
    setPeso(event.target.value);
  }

  function handleChangeCmcuello(event) {
    event.preventDefault();
    setCmcuello(event.target.value);
  }

  function handleChangeCmcadera(event) {
    event.preventDefault();
    setCmcadera(event.target.value);
  }

  function handleChangeCmcintura(event) {
    event.preventDefault();
    setCmcintura(event.target.value);
  }

  useEffect(() => {
    setData(location.state.detail);
  }, [location]);

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  function renderInfo() {
    return (
      <div>
        <div className={styles.OneSection}>
          <div className={styles.Third}>
            <MaterialIcon icon="person" size="small" color="#7bb92f" />
            <Typography>
              <b>Nombre</b> : {capitalize(data.nombre)}{" "}
              {capitalize(data.apellido)}
            </Typography>
          </div>
          <div className={styles.Third}>
            <MaterialIcon icon="pin" size="small" color="#7bb92f" />
            <Typography>
              <b>Edad</b> : {data.edad}
            </Typography>
          </div>
          <div className={styles.Third}>
            <MaterialIcon icon="apartment" size="small" color="#7bb92f" />
            <Typography>
              <b>Ciudad</b> : {capitalize(data.ciudad)}
            </Typography>
          </div>
          <div className={styles.Third}>
            <MaterialIcon icon="celebration" size="small" color="#7bb92f" />
            <Typography>
              <b>Cumpleaños</b> :{data.fechac}
            </Typography>
          </div>
          <div className={styles.Third}>
            <MaterialIcon icon="wc" size="small" color="#7bb92f" />
            <Typography>
              <b>Sexo</b> :{capitalize(data.sexo)}
            </Typography>
          </div>
          <div className={styles.Third}>
            <MaterialIcon icon="fitness_center" size="small" color="#7bb92f" />
            <Typography>
              <b>Peso</b> :{data.peso} kg.
            </Typography>
          </div>
          <div className={styles.Third}>
            <MaterialIcon icon="height" size="small" color="#7bb92f" />
            <Typography>
              <b>Altura</b> :{data.altura} cm.
            </Typography>
          </div>
          <div className={styles.Third}>
            <MaterialIcon icon="phone_iphone" size="small" color="#7bb92f" />
            <Typography>
              <b>Teléfono</b> : {data.telefono}
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Container maxWidth="xs" className={styles.PaddingUp}>
      <Loader isLoading={isLoading} />
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.Relleno}></div>
        <div className={styles.SubTitle}>
          Seguimiento a:{" "}
          <b>
            {capitalize(data.nombre) +
              " " +
              capitalize(data.apellido) +
              " " +
              data.fecha}
          </b>
        </div>
        <div className={styles.Card}>{renderInfo()}</div>
        <Typography variant="h6" className={styles.title}>
          Métricas Personales
        </Typography>
        <div className={styles.dates}>
          <div className={styles.middlethird}>
            <label htmlFor="peso" className={styles.Label}>
              Peso(kg)*
            </label>
            <input
              type="text"
              className={styles.Input}
              name="peso"
              required
              onChange={handleChangePeso}
              autoComplete="off"
            />
          </div>
          <div className={styles.middlethird}>
            <label htmlFor="cmcuello" className={styles.Label}>
              Cuello(cm)
            </label>
            <input
              type="text"
              className={styles.Input}
              name="cmcuello"
              onChange={handleChangeCmcuello}
              autoComplete="off"
            />
          </div>

          <div className={styles.middlethird}>
            <label htmlFor="cmcintura" className={styles.Label}>
              Cintura(cm)
            </label>
            <input
              type="text"
              className={styles.Input}
              name="cmcintura"
              onChange={handleChangeCmcintura}
              autoComplete="off"
            />
          </div>

          <div className={styles.middlethird}>
            <label htmlFor="cmcadera" className={styles.Label}>
              Cadera(cm)
            </label>
            <input
              type="text"
              className={styles.Input}
              name="cmcadera"
              onChange={handleChangeCmcadera}
              autoComplete="off"
            />
          </div>
        </div>
        <Typography className={styles.title}></Typography>
        <button type="submit" className={styles.button}>
          Ingresar Seguimiento
        </button>
      </form>
    </Container>
  );
};

export default Seguimiento;
