import { useEffect } from "react";

import PropTypes from "prop-types";

import { InputLabel } from "@material-ui/core";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { customDateStyles, customSelectStyles } from "../Customs/customStyles";

const propTypes = {
  filter: PropTypes.object.isRequired,
  appliedFilter: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
};

function CustomDynamicFiltersDateRangePicker({
  filter,
  appliedFilter,
  onFilterChange,
}) {
  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ///////// PRIVATE METHODS ////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  useEffect(() => {
    onFilterChange([new Date(), new Date()]);
  }, []);

  const config = filter.config;
  const dateInstance = new DateFnsUtils();

  function getDateString(date) {
    const day = date.getDate() < 10 ? `${0}${date.getDate()}` : date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const dateString = `${year}${month}${day}`;
    return dateString;
  }

  function shouldDisableStartDatePicker(currentDate) {
    const currentDateString = getDateString(currentDate);

    const appliedDate = [...appliedFilter.value];
    const endDate = appliedDate[1];

    const endDateString = getDateString(endDate);

    if (endDateString < currentDateString) {
      return true;
    }

    return false;
  }

  function shouldDisableEndDatePicker(currentDate) {
    const currentDateString = getDateString(currentDate);

    const appliedDate = [...appliedFilter.value];
    const startDate = appliedDate[0];

    const startDateString = getDateString(startDate);

    if (startDateString > currentDateString) {
      return true;
    }

    return false;
  }

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  //////// EVENT HANDLERS //////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function handleStartDateRageChange(date) {
    const appliedDate = [...appliedFilter.value];

    appliedDate[0] = date;

    onFilterChange(appliedDate);
  }

  function handleEndDateRageChange(date) {
    const appliedDate = [...appliedFilter.value];

    appliedDate[1] = date;

    onFilterChange(appliedDate);
  }

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ////////// RENDERERS /////////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function renderFilterDaterRange() {
    if (appliedFilter?.value == null) {
      return null;
    }

    const CustomMuiPicker = customSelectStyles(KeyboardDatePicker, config);
    const CustomDate = customDateStyles(CustomMuiPicker);

    let value = appliedFilter.value;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <InputLabel
          style={{
            color: "white",
          }}
        >
          {filter.label}
        </InputLabel>

        <CustomDate
          shouldDisableDate={shouldDisableStartDatePicker}
          disableToolbar
          format="dd/MM/yyyy"
          value={value[0]}
          onChange={handleStartDateRageChange}
          autoOk={true}
          margin="normal"
          label={filter.config?.startDateLabel ?? "Desde"}
          variant="inline"
          inputVariant="outlined"
        />

        <CustomDate
          shouldDisableDate={shouldDisableEndDatePicker}
          disableToolbar
          format="dd/MM/yyyy"
          value={value[1]}
          onChange={handleEndDateRageChange}
          autoOk={true}
          margin="normal"
          label={filter.config?.endDateLabel ?? "Hasta"}
          variant="inline"
          inputVariant="outlined"
        />
      </MuiPickersUtilsProvider>
    );
  }

  return renderFilterDaterRange();
}

CustomDynamicFiltersDateRangePicker.propTypes = propTypes;
export default CustomDynamicFiltersDateRangePicker;
