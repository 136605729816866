import PropTypes from "prop-types";

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

const propTypes = {
  filter: PropTypes.object.isRequired,
  appliedFilter: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
};

function CustomDynamicFilterRadioGroup({
  filter,
  appliedFilter,
  onFilterChange,
}) {
  //  //////////////////////////////////
  //  //////////////////////////////////
  //  //////// EVENT HANDLERS //////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function handleChange(event) {
    const { value } = event.target;
    onFilterChange(value);
  }

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ////////// RENDERERS /////////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function renderRadios() {
    const mappedOptions = filter.options.map(({ label, value }, index) => (
      //need unique id
      <FormControlLabel
        key={index}
        value={value}
        control={<Radio color="primary" style={{ color: "#FF0000" }} />}
        label={label}
      />
    ));

    return mappedOptions;
  }

  function renderRadioGroup() {
    let value;
    if (appliedFilter != null) {
      value = appliedFilter.value;
    } else {
      value = "";
    }

    return (
      <FormControl component="fieldset">
        <FormLabel component="legend" style={{ color: "#FFF" }}>
          {filter.label}
        </FormLabel>
        <RadioGroup
          style={{ flexDirection: filter.options.radioGroupDirection ?? "row" }}
          aria-label={filter.label}
          value={value}
          onChange={handleChange}
        >
          {renderRadios()}
        </RadioGroup>
      </FormControl>
    );
  }

  return renderRadioGroup();
}

CustomDynamicFilterRadioGroup.propTypes = propTypes;
export default CustomDynamicFilterRadioGroup;
