import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import apiCall from "../../../services/apiCall";

import makeStyles from "@material-ui/core/styles/makeStyles";

import Alert from "../../Alert";
import Loader from "../../Loader";
import Menu from "../../Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
  },
}));

const propTypes = {
  apiUrl: PropTypes.string,
};

const defaultProps = {
  apiUrl: "/prod/getmenu",
};

function MenuContainer({ apiUrl }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [currentPageTitle, setCurrentPageTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ isOpen: false, message: "" });
  const [menu, setMenu] = useState([]);
  const role = localStorage.getItem("role");

  if (role === "supervisor") {
    apiUrl = "/prod/menu/supervisor";
  } else if (role === "capitan") {
    apiUrl = "/prod/menu/capitan";
  } else if (role === "admin") {
    apiUrl = "/prod/menu/admin";
  } else if (role === "ambos") {
    apiUrl = "/prod/menu/ambos";
  }

  async function getData(url) {
    setIsLoading(true);
    const response = await apiCall(url);
    setIsLoading(false);

    if (response instanceof Error) {
      setAlert({ isOpen: true, message: response.message });
      return null;
    } else {
      setAlert({ isOpen: false, message: "" });
      return response;
    }
  }

  async function searchMenu() {
    const menu = await getData(apiUrl);

    if (menu) {
      setMenu(menu);
      getCurrentPageTitle(menu);
    }
  }

  function getCurrentPageTitle() {
    const findedMenuItem = menu?.find((menuItem) => menuItem.url === pathname);
    const pageTitle = findedMenuItem?.pageTitle ?? "";

    setCurrentPageTitle(pageTitle);
  }

  useEffect(() => {
    searchMenu();
  }, []);

  useEffect(() => {
    getCurrentPageTitle();
  }, [menu, pathname]);

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  //////// EVENT HANDLERS //////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function handleCloseAlert() {
    setAlert({ isOpen: false, message: "" });
  }

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ////////// RENDERERS /////////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className={classes.root}>
        <Menu currentPageTitle={currentPageTitle} menu={menu} />
      </div>
      <Alert
        isOpen={alert.isOpen}
        message={alert.message}
        onClose={handleCloseAlert}
      />
    </>
  );
}

MenuContainer.propTypes = propTypes;
MenuContainer.defaultProps = defaultProps;
export default MenuContainer;
