import { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import filterType from "./filterType";
import styles from "./styles.module.scss";

const propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      config: PropTypes.object,
    })
  ).isRequired,
  appliedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      value: PropTypes.any,
    })
  ).isRequired,
  onAppliedFiltersChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  showApplyFiltersButton: PropTypes.bool,
  onSubmitFilters: PropTypes.func,
  disableApplyFiltersButton: PropTypes.bool,
};

const defaultProps = {
  appliedFilters: [
    { key: "supermercado", type: "bool", value: true },
    {
      key: "fecha-de-entrega",
      type: "select",
      value: "05-17-2021",
    },
  ],
  filters: [
    {
      key: "supermercado",
      label: "Supermercado",
      type: "bool",
      config: {
        displayOrder: 2,
        gridXs: 2,
        direction: "row",
        alignItems: "center",
        justify: "center",
      },
    },
    {
      key: "fecha-de-entrega",
      label: "Fecha de Entrega",
      type: "select",
      options: [
        { value: "05-15-2021", label: "05-15-2021" },
        { value: "05-16-2021", label: "05-16-2021" },
        { value: "05-17-2021", label: "05-17-2021" },
      ],
      config: {
        displayOrder: 1,
        gridXs: 6,
        direction: "row",
        alignItems: "center",
        justify: "flex-end",
        width: "50%",
        showFilterGridLabel: false,
      },
    },
    {
      key: "zonas",
      label: "Zonas",
      type: "select",
      options: [
        { value: "05-15-2021", label: "05-15-2021" },
        { value: "05-16-2021", label: "05-16-2021" },
        { value: "05-17-2021", label: "05-17-2021" },
        { value: "05-18-2021", label: "05-18-2021" },
        { value: "05-19-2021", label: "05-19-2021" },
        { value: "05-20-2021", label: "05-20-2021" },
        { value: "05-21-2021", label: "05-21-2021" },
        { value: "05-22-2021", label: "05-22-2021" },
      ],
      config: {
        displayOrder: 1,
        gridXs: 6,
        direction: "row",
        width: "50%",
        alignItems: "center",
        justify: "flex-start",
        showFilterGridLabel: false,
        isMulti: true,
      },
    },

    {
      key: "campos",
      label: "Campos",
      type: "date",
      config: {
        displayOrder: 11,
        gridXs: 3,
        direction: "row",
        alignItems: "center",
        justify: "center",
        showFilterGridLabel: false,
      },
    },
    {
      key: "parques",
      label: "Parques",
      type: "dateRange",
      config: {
        displayOrder: 12,
        gridXs: 12,
        direction: "row",
        alignItems: "center",
        justify: "space-evenly",
        width: "42%",
        startDateLabel: "Desde",
        endDateLabel: "Hasta",
        showFilterGridLabel: false,
      },
    },
    {
      key: "radio-filters",
      label: "test",
      type: "radioGroup",
      options: [
        { value: "transportes", label: "#Transportes" },
        { value: "uc", label: "UC" },
        { value: "puntos", label: "#Puntos" },
      ],
      config: {
        displayOrder: 12,
        gridXs: 12,
        direction: "row",
        alignItems: "center",
        justify: "space-evenly",
        width: "42%",
        startDateLabel: "Desde",
        endDateLabel: "Hasta",
        showFilterGridLabel: false,
        radioGroupDirection: "row",
      },
    },
  ],
  onAppliedFiltersChange: () => console.log("onAppliedFiltersChange"),
  isLoading: false,
  showApplyFiltersButton: true,
  showClearFiltersButton: false,
  onSubmitFilters: () => console.log("onSubmitFilters"),
  disableApplyFiltersButton: false,
  disableClearFiltersButton: false,
};

function DynamicFilter({
  filters,
  appliedFilters,
  onAppliedFiltersChange,
  onSubmitFilters,
  isLoading,
  className,
  showApplyFiltersButton,
  disableApplyFiltersButton,
  showClearFiltersButton,
  disableClearFiltersButton,
}) {
  //  //////////////////////////////////
  //  //////////////////////////////////
  //  //////// EVENT HANDLERS //////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function handleSubmitFilters() {
    onSubmitFilters();
  }

  function handleClearAppliedFilters() {
    onAppliedFiltersChange([]);
  }

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ////////// RENDERERS /////////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function renderFilters() {
    const filtersDataCopy = [...filters];
    const sortedFilters = filtersDataCopy.sort(
      (a, b) => a.config?.displayOrder - b.config?.displayOrder
    );

    const mappedFilters = sortedFilters.map((filter) => {
      const renderFilter = filterType({
        filter,
        appliedFilters,
        onAppliedFiltersChange,
      });

      if (!renderFilter[filter.type]) {
        return null;
      }

      let filterGridLabel = <span>{filter.label}</span>;

      if (filter.config?.showFilterGridLabel === false) {
        filterGridLabel = null;
      }

      return (
        <Grid
          key={filter.key}
          container
          item
          alignItems={filter.config?.alignItems ?? "center"}
          direction={filter.config?.direction ?? "row"}
          justify={filter.config?.justify ?? "center"}
          xs={filter.config?.gridXs}
          lg={filter.config?.gridLg}
          sm={filter.config?.gridSm}
        >
          {filterGridLabel}
          {renderFilter[filter.type]}
        </Grid>
      );
    });

    return mappedFilters;
  }

  function renderApplyFiltersButton() {
    if (!showApplyFiltersButton) {
      return null;
    }

    let buttonProps = {};

    if (isLoading || appliedFilters.length === 0 || disableApplyFiltersButton) {
      buttonProps["disabled"] = true;
    }

    return (
      <Button
        {...buttonProps}
        color="primary"
        variant="contained"
        className={styles.Button}
        onClick={handleSubmitFilters}
      >
        Apply filters
      </Button>
    );
  }

  function renderClearFiltersButton() {
    if (!showClearFiltersButton) {
      return null;
    }

    let buttonProps = {};

    if (isLoading || appliedFilters.length === 0 || disableClearFiltersButton) {
      buttonProps["disabled"] = true;
    }

    return (
      <Button
        {...buttonProps}
        color="primary"
        variant="contained"
        className={styles.ClearButton}
        onClick={handleClearAppliedFilters}
      >
        Clear
      </Button>
    );
  }

  return (
    <Grid container className={classNames(styles.FiltersContainer, className)}>
      {renderFilters()}
      {renderApplyFiltersButton()}
      {renderClearFiltersButton()}
    </Grid>
  );
}

DynamicFilter.propTypes = propTypes;
DynamicFilter.defaultProps = defaultProps;
export default memo(DynamicFilter);
