import { withStyles } from "@material-ui/core";

function customCheckboxStyles(component, color) {
  return withStyles({
    root: {
      color: color,
    },
    checked: {
      color: "#FF0000 !important",
    },
  })(component);
}

function customSelectStyles(component, config) {
  return withStyles({
    root: {
      width: config.width ? config.width : "100%",
      color: "white",
      "& label.Mui-focused": {
        color: "red",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "green",
      },

      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "green",
        },
        "&:hover fieldset": {
          borderColor: "white",
        },
        "&.Mui-focused fieldset": {
          borderColor: "green",
        },
      },
    },
  })(component);
}

function customSearchSelectStyles(component, config) {
  return withStyles({
    root: {
      width: config.width ? config.width : "100%",
      color: "white",
      "& label": {
        color: "white",
      },
      "& label.Mui-focused": {
        color: "white",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "green",
      },

      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "green",
        },
        "&:hover fieldset": {
          borderColor: "white",
        },
        "&.Mui-focused fieldset": {
          borderColor: "green",
        },
      },
    },
  })(component);
}

function customInputStyles(component, config) {
  return withStyles({
    root: {
      width: config.width ? config.width : "100%",
      color: "white",
      "& .MuiFormLabel-root": {
        color: "white",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "green",
        },
        "&:hover fieldset": {
          borderColor: "white",
        },
        "&.Mui-focused fieldset": {
          borderColor: "green",
        },
      },
    },
  })(component);
}

function customDateStyles(component) {
  return withStyles({
    root: {
      "& label.Mui-focused": {
        color: "white",
      },
      "& .MuiFormLabel-root": {
        color: "white",
      },
      "& .MuiSvgIcon-root": {
        color: "white",
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
      "& .MuiInputBase-input": {
        color: "white",
      },
    },
  })(component);
}

export {
  customCheckboxStyles,
  customSelectStyles,
  customDateStyles,
  customInputStyles,
  customSearchSelectStyles,
};
