import {
  Checkbox,
  Select,
  DatePicker,
  DateRangePicker,
  RadioGroup,
  Text,
} from "../DynamicFilter/Customs";

function filterType({ filter, appliedFilters, onAppliedFiltersChange }) {
  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ///////// PRIVATE METHODS ////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function findAppliedFilter() {
    const findedFilter = appliedFilters.find(
      (appliedFilter) => appliedFilter.key === filter.key
    );

    if (findedFilter !== undefined) {
      return findedFilter;
    }
    return null;
  }

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  //////// EVENT HANDLERS //////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function handleFilterChange(value) {
    const oldAppliedFilters = [...appliedFilters];
    const filteredNewAppliedFilters = oldAppliedFilters.filter(
      (item) => item.key !== filter.key
    );
    let newAppliedFilters = [...filteredNewAppliedFilters];

    function pushToAppliedFilters() {
      newAppliedFilters.push({
        key: filter.key,
        type: filter.type,
        value: value,
      });
    }

    if (Array.isArray(value)) {
      if (value.length > 0) {
        pushToAppliedFilters();
      }
    } else {
      if (value != null) {
        pushToAppliedFilters();
      }
    }

    onAppliedFiltersChange(newAppliedFilters);
  }

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ////////// RENDERERS /////////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function renderFilter(component) {
    const Component = component;
    const appliedFilter = findAppliedFilter();

    return (
      <Component
        filter={filter}
        appliedFilter={appliedFilter}
        onFilterChange={handleFilterChange}
      />
    );
  }

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ////////// FACTORY ///////////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  return {
    bool: renderFilter(Checkbox),
    select: renderFilter(Select),
    date: renderFilter(DatePicker),
    dateRange: renderFilter(DateRangePicker),
    radioGroup: renderFilter(RadioGroup),
    text: renderFilter(Text),
  };
}
export default filterType;
