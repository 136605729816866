import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Pool from "../../services/UserPool";

export default function None() {
  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
  }));

  let history = useHistory();
  const classes = useStyles();

  function onUserLogout() {
    const user = Pool.getCurrentUser();
    window.localStorage.clear();
    if (user) {
      user.signOut();
    }
    history.push("/");

    return null;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Typography variant="h5" className={styles.hh1}>
        USUARIO NO ACTIVADO
      </Typography>
      <p className={styles.None}>
        Debes enviar los siguientes datos al Administrador para la activación:
      </p>
      <p className={styles.None}>
        nombre, apellido, numero celular y el correo electrónico con el cual te
        registraste.
      </p>
      <div style={{ textAlign: "center", paddingTop: "25px" }}>
        <ExitToAppIcon
          className={classes.exitToAppIcon}
          onClick={() => onUserLogout()}
        />
        <Typography variant="h6">Salir</Typography>
      </div>
    </Container>
  );
}
