import { useState, useEffect, memo, useMemo } from "react";

import Container from "@material-ui/core/Container";
import MaterialIcon from "material-icons-react";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Alert from "../../../components/Alert";
import Loader from "../../../components/Loader";
import TableUI from "../../../components/TablesUI";
import apiCall from "../../../services/apiCall";

import styles from "./styles.module.scss";

function ShowAllUsers() {
  const MySwal = withReactContent(Swal);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ isOpen: false, message: "" });

  const memoizedTable = useMemo(() => renderTable(data.table), [data.table]);

  async function getData(url, verb, payload) {
    setIsLoading(true);
    const response = await apiCall(url, verb, payload);
    setIsLoading(false);

    if (response instanceof Error) {
      setAlert({ isOpen: true, message: response.message });
      return null;
    } else {
      setAlert({ isOpen: false, message: "" });
      return response;
    }
  }

  async function getdataTable(url) {
    const data = await getData(url);

    if (data) {
      setData(data);
    }
  }

  async function initData() {
    const url = `/prod/members/show-all`;

    getdataTable(url);
  }

  useEffect(() => {
    initData();
  }, []);

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  //////// EVENT HANDLERS //////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function handleCloseAlert() {
    setAlert({ isOpen: false, message: "" });
  }

  async function handleClickDelete(row) {
    const delet = {
      ID: row.ID,
    };

    MySwal.fire({
      title: `Estas seguro de que quieres borrar al usuario ${row.email}?`,
      showDenyButton: true,
      showConfirmButton: false,
      showCancelButton: true,
      denyButtonText: "Eliminar",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isDenied) {
        const result = await getData(`/prod/members/delete`, "post", delet);

        if (result.statusCode === 200) {
          MySwal.fire("El usuario ha sido eliminado!", "", "success");
          initData();
        } else {
          MySwal.fire("El usuario no se elimino", "", "info");
        }
      }
    });
  }

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ////////// RENDERERS /////////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function renderTable(table) {
    if (table == null) {
      return null;
    }
    const columns = [
      {
        name: "Eliminar",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <button
                style={{ all: "unset", cursor: "pointer" }}
                onClick={() => {
                  handleClickDelete(tableMeta.tableData[tableMeta.rowIndex]);
                }}
                className={styles.ButtonShow}
              >
                <MaterialIcon icon="delete_forever" size="medium" color="red" />
              </button>
            );
          },
        },
      },
      {
        name: "fecha",
        label: "Fecha Inscrito",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "email",
        label: "Correo",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "tipo",
        label: "Tipo",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "nombre",
        label: "Nombre",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "apellido",
        label: "Apellido",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "rango",
        label: "Telefono",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "alias",
        label: "Alias",
        options: {
          filter: true,
          sort: true,
        },
      },
    ];

    const { data, config } = table;

    return <TableUI data={data} columns={columns} config={config} />;
  }

  return (
    <Container maxWidth="lg" disableGutters>
      <Loader isLoading={isLoading} />
      <Alert
        isOpen={alert.isOpen}
        message={alert.message}
        onClose={handleCloseAlert}
      />
      {memoizedTable}
    </Container>
  );
}

export default memo(ShowAllUsers);
