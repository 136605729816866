import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import iconFactory from "./iconFactory";

const link = forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

const propTypes = {
  item: PropTypes.object,
  expandedSections: PropTypes.array,
  onSectionClick: PropTypes.func,
  onMenuClose: PropTypes.func,
};

const defaultProps = {
  item: {
    key: "logistica",
    parent: "",
    label: "Logistica",
    url: "",
    config: {},
    icon: "truck",
    borderRadius: "10px",
  },
  expandedSections: [],
  onSectionClick: () => console.log("onSectionClick"),
  onMenuClose: () => console.log("onMenuClose"),
};

function MenuItem({ item, expandedSections, onSectionClick, onMenuClose }) {
  function handleSectionClick() {
    onSectionClick(item.key);
  }

  function handleCloseMenu() {
    onMenuClose();
  }

  function renderExpandedIcon() {
    if (item.url !== "") {
      return null;
    }

    if (expandedSections?.includes(item.key)) {
      return (
        <ExpandLessIcon
          style={{ color: item.background === "#229954" ? "#fff" : "#000" }}
        />
      );
    }

    return (
      <ExpandMoreIcon
        style={{ color: item.background === "#229954" ? "#fff" : "#000" }}
      />
    );
  }

  function renderIcon() {
    const renderedIcon = iconFactory();
    return renderedIcon[item.icon];
  }

  function renderItem() {
    if (item.parent && !expandedSections?.includes(item.parent)) {
      return null;
    }

    let itemProps = {};

    if (!item.url) {
      itemProps = {
        onClick: handleSectionClick,
      };
    }

    if (item.config) {
      itemProps = {
        ...itemProps,
        style: {
          padding: item.config.padding,
        },
      };
    }

    return (
      <List
        style={{
          color: item.background === "#229954" ? "#fff" : "#000",
          backgroundColor: item.background ?? "transparent",
          borderRadius: "50px",
          marginBottom: "5px",
          padding: "0px",
        }}
      >
        <ListItem key={item.key} button {...itemProps}>
          <ListItemIcon style={{ color: item.color ?? "#FFF" }}>
            {renderIcon()}
          </ListItemIcon>
          <ListItemText primary={item.label} />
          <ListItemIcon
            style={{ color: item.color ?? "#FFF", justifyContent: "end" }}
          >
            {renderExpandedIcon()}
          </ListItemIcon>
        </ListItem>
      </List>
    );
  }

  function renderItemLink() {
    return (
      <Link
        component={link}
        to={item.url}
        style={{ textDecoration: "none" }}
        onClick={handleCloseMenu}
      >
        {renderItem()}
      </Link>
    );
  }

  if (item.parent && item.url) {
    return renderItemLink();
  }

  return renderItem();
}
MenuItem.propTypes = propTypes;
MenuItem.defaultProps = defaultProps;
export default MenuItem;
