import { useState, useEffect, memo, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Alert from "../../../components/Alert";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Loader from "../../../components/Loader";
import TableUI from "../../../components/TablesUI";
import apiCall from "../../../services/apiCall";
import MaterialIcon from "material-icons-react";
import styles from "./styles.module.scss";

function ShowAllReto21() {
  const MySwal = withReactContent(Swal);
  let history = useHistory();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ isOpen: false, message: "" });

  const memoizedTable = useMemo(() => renderTable(data.table), [data.table]);

  async function getdataTable(url) {
    const data = await getData(url);

    if (data) {
      setData(data);
    }
  }

  async function getData(url, verb, payload) {
    setIsLoading(true);
    const response = await apiCall(url, verb, payload);
    setIsLoading(false);

    if (response instanceof Error) {
      setAlert({ isOpen: true, message: response.message });
      return null;
    } else {
      setAlert({ isOpen: false, message: "" });
      return response;
    }
  }

  async function initData() {
    const url = `/prod/reto21/show-all`;

    getdataTable(url);
  }

  useEffect(() => {
    initData();
  }, []);

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  //////// EVENT HANDLERS //////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function handleCloseAlert() {
    setAlert({ isOpen: false, message: "" });
  }

  async function handleClickDelete(row) {
    const delet = {
      ID: row.ID,
    };

    MySwal.fire({
      title: `Estas seguro de que quieres borrar el reto21?`,
      showDenyButton: true,
      showConfirmButton: false,
      showCancelButton: true,
      denyButtonText: "Eliminar",
    }).then(async (result) => {
      if (result.isDenied) {
        const result = await getData(`/prod/reto21/delete`, "post", delet);

        if (result.statusCode === 200) {
          MySwal.fire("El reto 21 ha sido eliminado!", "", "success");
          initData();
        } else {
          MySwal.fire("El reto 21 no se elimino", "", "info");
        }
      }
    });
  }

  async function handleClickEv(row) {
    history.push({
      pathname: "/reto21/add-supervisores",
      state: { detail: row },
    });
  }

  function handleClickCerrar(row) {
    const cerrar = {
      ID: row.ID,
    };

    MySwal.fire({
      title: `¿Estas seguro que quieres cerrar el Reto21 del ${row.fecha} ?, De seguir se procederá a calcular los resultados`,
      showDenyButton: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      icon: "question",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await getData(`/prod/reto21/cerrar`, "post", cerrar);

        if (result.statusCode === 200) {
          MySwal.fire(
            "Has cerrado el Reto21!, puedes ver los resultados en mis retos.",
            "",
            "success"
          );
          initData();
        } else {
          MySwal.fire("No se pudo cerrar el reto21", "", "error");
        }
      }
    });
  }

  async function handleClickPre(row) {
    history.push({
      pathname: "/reto21/ver-competencia-preliminar",
      state: { Reto: row },
    });
  }
  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ////////// RENDERERS /////////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function renderTable(table) {
    if (table == null) {
      return null;
    }
    const columns = [
      {
        name: "Eliminar",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <button
                style={{ all: "unset", cursor: "pointer" }}
                onClick={() => {
                  handleClickDelete(tableMeta.tableData[tableMeta.rowIndex]);
                }}
                className={styles.ButtonShow}
              >
                <MaterialIcon icon="delete_forever" size="medium" color="red" />
              </button>
            );
          },
        },
      },
      {
        name: "Agregar Supervisor",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            if (tableMeta.tableData[tableMeta.rowIndex]["estado"] == "Activo") {
              return (
                <button
                  style={{ all: "unset", cursor: "pointer" }}
                  onClick={() => {
                    handleClickEv(tableMeta.tableData[tableMeta.rowIndex]);
                  }}
                >
                  <MaterialIcon
                    icon="supervisor_account"
                    size="medium"
                    color="#1C04E5"
                  />
                </button>
              );
            } else {
              return (
                <p style={{ color: "#F30517", fontFamily: "fantasy" }}>
                  Reto21 Cerrado
                </p>
              );
            }
          },
        },
      },
      {
        name: "Result. Prel.",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            if (tableMeta.tableData[tableMeta.rowIndex]["estado"] == "Activo") {
              return (
                <button
                  style={{ all: "unset", cursor: "pointer" }}
                  onClick={() => {
                    handleClickPre(tableMeta.tableData[tableMeta.rowIndex]);
                  }}
                >
                  <MaterialIcon icon="preview" size="medium" color="#229954" />
                </button>
              );
            } else {
              return (
                <p style={{ color: "#F30517", fontFamily: "fantasy" }}>
                  Reto21 Cerrado
                </p>
              );
            }
          },
        },
      },
      {
        name: "Cerrar Reto21",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            if (tableMeta.tableData[tableMeta.rowIndex]["estado"] == "Activo") {
              return (
                <button
                  style={{ all: "unset", cursor: "pointer" }}
                  onClick={() => {
                    handleClickCerrar(tableMeta.tableData[tableMeta.rowIndex]);
                  }}
                >
                  <MaterialIcon icon="closed" size="medium" color="#ED7348" />
                </button>
              );
            } else {
              return (
                <p style={{ color: "#F30517", fontFamily: "fantasy" }}>
                  Cerrado
                </p>
              );
            }
          },
        },
      },
      {
        name: "fecha",
        label: "Fecha Inicio",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "estado",
        label: "Estado",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "supervisores",
        label: "Supervisores",
        options: {
          filter: true,
          sort: true,
          display: false,
        },
      },
    ];

    const { data, config } = table;

    return <TableUI data={data} columns={columns} config={config} />;
  }

  return (
    <Container maxWidth="xl" disableGutters>
      <Loader isLoading={isLoading} />
      <Alert
        isOpen={alert.isOpen}
        message={alert.message}
        onClose={handleCloseAlert}
      />
      {memoizedTable}
    </Container>
  );
}

export default memo(ShowAllReto21);
