import { Route, Redirect } from "react-router-dom";
import { isEmpty } from "lodash";

function ProtectedRoute({ children, requiredRoles, ...rest }) {
  const role = localStorage.getItem("role");
  let isAuthenticated = false;

  const sessions = localStorage.getItem("Token");
  if (!isEmpty(sessions)) {
    isAuthenticated = true;
  }

  const userHasRequiredRole = requiredRoles.includes(role);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated || !userHasRequiredRole) {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }

        return children;
      }}
    />
  );
}

export default ProtectedRoute;
