import PropTypes from "prop-types";
import MaterialAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  showCloseIcon: PropTypes.bool,
};

const defaultProps = {
  isOpen: true,
  message: "Error",
  severity: "error",
  onClose: () => console.log("onClose alert"),
  showCloseIcon: true,
};

const useStyles = makeStyles((theme) => ({
  AlertContainer: {
    width: "100%",
  },
  Alert: {
    height: "35px",
  },
}));

function Alert({ isOpen, message, severity, onClose, showCloseIcon }) {
  const classes = useStyles();
  let alertProps = {};

  if (showCloseIcon) {
    alertProps = {
      action: (
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ),
    };
  }

  return (
    <div className={classes.AlertContainer}>
      <Collapse in={isOpen}>
        <MaterialAlert
          className={classes.Alert}
          severity={severity}
          {...alertProps}
        >
          {message}
        </MaterialAlert>
      </Collapse>
    </div>
  );
}

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;
export default Alert;
