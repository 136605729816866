import PropTypes from "prop-types";

import { Checkbox } from "@material-ui/core";

import { customCheckboxStyles } from "../Customs/customStyles";

const propTypes = {
  appliedFilter: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
};

function CustomDynamicFiltersCheckbox({ appliedFilter, onFilterChange }) {
  //  //////////////////////////////////
  //  //////////////////////////////////
  //  //////// EVENT HANDLERS //////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function handleChangeCheckbox(event) {
    const value = event.target.checked;

    if (value) {
      onFilterChange(value);
    } else {
      onFilterChange(null);
    }
  }

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ////////// RENDERERS /////////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function renderFilterBool() {
    const CustomCheckbox = customCheckboxStyles(Checkbox, "white");
    let value;

    if (appliedFilter != null) {
      value = appliedFilter.value;
    } else {
      value = false;
    }

    return <CustomCheckbox checked={value} onChange={handleChangeCheckbox} />;
  }

  return renderFilterBool();
}

CustomDynamicFiltersCheckbox.propTypes = propTypes;
export default CustomDynamicFiltersCheckbox;
