import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";
import MaterialIcon from "material-icons-react";
import Container from "@material-ui/core/Container";

import Loader from "../../../components/Loader";
import apiCall from "../../../services/apiCall";
import styles from "./styles.module.scss";

import { faFemale } from "@fortawesome/free-solid-svg-icons";
import { faMale } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function EditarEv() {
  const MySwal = withReactContent(Swal);
  let history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [evaluation, setEvaluation] = useState({
    nombre: "",
    apellido: "",
    ciudad: "",
    telefono: "",
    fechac: "",
    referido: "",
  });

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    let count = 0;
    for (var prop in evaluation) {
      if (evaluation[prop] == "") count += 1;
    }
    console.log(count);
    console.log(evaluation);
    if (count == 6) {
      setIsLoading(false);
      MySwal.fire("No cambio ningun dato", "", "info");
    } else {
      const editJson = {
        ID: data.ID,
        fecha: data.fecha,
        edicion: evaluation,
      };

      console.log(editJson);
      const result = await apiCall(
        `/prod/evaluation/edicion`,
        "post",
        editJson
      );

      setIsLoading(false);

      if (result.statusCode === 200) {
        history.push("/evaluation/show-all");
      } else {
        console.log(result);
      }
    }

    return null;
  }

  useEffect(() => {
    setData(location.state.detail);
  }, [location]);

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  function handleChangeEvaluation(event, args) {
    let { name, value } = event.target;

    if (name === "sexo") {
      value = args;
    }

    setEvaluation({
      ...evaluation,
      [name]: value,
    });
  }

  return (
    <Container maxWidth="xs" className={styles.PaddingUp}>
      <Loader isLoading={isLoading} />
      <form onSubmit={handleSubmit} className={styles.form}>
        <Typography variant="h6" className={styles.title}>
          <b>
            {capitalize(data.nombre) +
              " " +
              capitalize(data.apellido) +
              " " +
              data.fecha}
          </b>
        </Typography>
        <div className={styles.middle}>
          <label for="nombre" className={styles.Label}>
            Name*
          </label>
          <input
            type="text"
            className={styles.Input}
            name="nombre"
            onChange={handleChangeEvaluation}
            placeholder={data.nombre}
            autocomplete="off"
          />
        </div>
        <div className={styles.middle}>
          <label for="apellido" className={styles.Label}>
            Apellido
          </label>
          <input
            type="text"
            className={styles.Input}
            name="apellido"
            onChange={handleChangeEvaluation}
            placeholder={data.apellido}
            autocomplete="off"
          />
        </div>
        <div className={styles.middle}>
          <label for="ciudad" className={styles.Label}>
            Ciudad*
          </label>
          <input
            type="text"
            className={styles.Input}
            name="ciudad"
            onChange={handleChangeEvaluation}
            placeholder={data.ciudad}
            autocomplete="off"
          />
        </div>
        {/* <div className={styles.middle}>
          <label for="edad" className={styles.Label}>
            Edad*
          </label>
          <input
            type="text"
            className={styles.Input}
            name="edad"
            onChange={handleChangeEvaluation}
            placeholder={data.edad}
            autocomplete="off"
          />
        </div> */}
        <div className={styles.middle}>
          <label for="fechac" className={styles.Label}>
            Fecha Cumpleaños (año-mes-dia)
          </label>
          <input
            type="date"
            className={styles.Input}
            name="fechac"
            onChange={handleChangeEvaluation}
            defaultValue={data.fechac}
          />
        </div>
        <div className={styles.middle}>
          <label for="telefono" className={styles.Label}>
            N Celular*
          </label>
          <input
            type="tel"
            className={styles.Input}
            name="telefono"
            onChange={handleChangeEvaluation}
            placeholder={data.telefono}
          />
        </div>
        {/* <div className={styles.middle}>
          <label htmlFor="sexo" className={styles.Label}>
            Sexo
          </label>
          <div className={styles.Sexo}>
            <input
              name="sexo"
              style={{ zoom: "2" }}
              type="radio"
              onChange={(event) => handleChangeEvaluation(event, "femenino")}
            />
            <FontAwesomeIcon icon={faFemale} size="2x" />

            <input
              name="sexo"
              style={{ zoom: "2" }}
              type="radio"
              onChange={(event) => handleChangeEvaluation(event, "masculino")}
            />
            <FontAwesomeIcon icon={faMale} size="2x" />
          </div>
        </div> */}
        <div className={styles.middle}>
          <label for="referido" className={styles.Label}>
            Referido
          </label>
          <input
            type="text"
            className={styles.Input}
            name="referido"
            onChange={handleChangeEvaluation}
            placeholder={data.referido}
            autocomplete="off"
          />
        </div>
        <Typography className={styles.title}></Typography>
        <button type="submit" className={styles.button}>
          EDITAR
        </button>
      </form>
    </Container>
  );
}

export default EditarEv;
