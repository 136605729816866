import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import styles from "./styles.module.scss";
import classNames from "classnames";

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  isLoading: true,
};

function Loader({ isLoading, className }) {
  if (!isLoading) {
    return null;
  }

  return (
    <div className={classNames(styles.Loader, className)}>
      <LinearProgress />
    </div>
  );
}

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;
export default Loader;
