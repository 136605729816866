import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";

import styles from "./styles.module.scss";

function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://teamlifes.com/">
        TEAMLIFES APP
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function HomeApp() {
  return (
    <div className={styles.Background}>
      <div className={styles.Copy}>
        <Copyright />
      </div>
    </div>
  );
}
