import { useState } from "react";
import PropTypes from "prop-types";

import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuItem from "../Menu/MenuItem";

import MenuImage from "../../assets/images/avatarMenu.png";
import thanosLogoAppbar from "../../assets/images/logo-menu.png";
import styles from "./styles.module.scss";
import { useHistory } from "react-router";

import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  menuListContainer: {
    width: 350,
    height: "100vh",
    backgroundColor: "#f3f3f3",
    color: "white",
    boxSizing: "border-box",
    padding: "0px 10px",
    position: "relative",
  },
  menuList: {
    backgroundColor: "#f3f3f3",
  },
  avatarContainer: {
    paddingTop: "50px",
    paddingBottom: "30px",
    paddingLeft: "30px",
  },
  avatar: {
    width: "80px",
    height: "auto",
    justifyContent: "start",
    borderRadius: "0px",
  },
  BackdropProps: {
    borderRadius: "0px 20px 20px 0px",
  },
  menuIcon: {
    transform: "rotate(90deg)",
    "& .MuiSvgIcon-root": {
      fill: "#A8A8A8",
    },
    "&:hover": {
      "& .MuiSvgIcon-root": {
        fill: "#7c7c7c",
      },
    },
  },
}));

const propTypes = {
  menuTitle: PropTypes.string,
  currentPageTitle: PropTypes.string,
  user: PropTypes.string,
  email: PropTypes.string,
  menu: PropTypes.array,
};

function Menu({ menuTitle, currentPageTitle, user, menu }) {
  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ///////// PRIVATE METHODS ////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  const classes = useStyles();
  let history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [expandedSections, setExpandedSections] = useState([]);

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  //////// EVENT HANDLERS //////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function handleOpenMenu() {
    setIsMenuOpen(true);
  }

  function handleCloseMenu() {
    setIsMenuOpen(false);
  }

  function onUserLogout() {
    localStorage.clear();
    history.push("/");
  }

  function handleExpandSection(section) {
    if (expandedSections.includes(section)) {
      let expandedSectionsCopy = [...expandedSections];

      for (let index = 0; index < expandedSections.length; index++) {
        const element = expandedSections[index];
        const findedItem = menu.find((item) => item.key === element);

        if (findedItem.parent === section || element === section) {
          expandedSectionsCopy = expandedSectionsCopy.filter(
            (item) => item !== element
          );
        }
      }

      setExpandedSections(expandedSectionsCopy);
    } else {
      const newExpandedSections = [...expandedSections, section];
      setExpandedSections(newExpandedSections);
    }
  }

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ////////// RENDERERS /////////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function renderMenuList() {
    if (menu.length > 0 === false) {
      return null;
    }

    const mappedSections = menu.map((item) => (
      <MenuItem
        key={item.key}
        item={item}
        expandedSections={expandedSections}
        onSectionClick={handleExpandSection}
        onMenuClose={handleCloseMenu}
      />
    ));

    return (
      <List className={classes.menuList}>
        <div className={classes.avatarContainer}>
          <Avatar alt="Avatar" src={MenuImage} className={classes.avatar} />
        </div>
        {mappedSections}
      </List>
    );
  }

  const matches = useMediaQuery("(max-width:1024px)");

  return (
    <>
      <AppBar color="primary" position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuIcon}
            aria-label="menu"
            onClick={handleOpenMenu}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={thanosLogoAppbar}
            className={styles.appbarLogo}
            alt="ThanosUltra"
          />
          <div className={styles.HeaderTitlesContainer}>
            <Box className={styles.logout}>
              <ExitToAppIcon
                className={classes.exitToAppIcon}
                onClick={() => onUserLogout()}
              />
              {matches ? (
                ""
              ) : (
                <Typography variant="body1">Cerrar sesión</Typography>
              )}
            </Box>
          </div>
        </Toolbar>
        <SwipeableDrawer
          open={isMenuOpen}
          onClose={handleCloseMenu}
          onOpen={handleOpenMenu}
          PaperProps={{
            classes: {
              root: classes.BackdropProps,
            },
          }}
        >
          <div
            className={classes.menuListContainer}
            role="presentation"
            onKeyDown={handleCloseMenu}
          >
            <Box className={styles.swipeableIcon}>
              <div></div>
              <div></div>
              <div></div>
            </Box>
            {renderMenuList()}
          </div>
        </SwipeableDrawer>
      </AppBar>
      <Box className={styles.pageTitles}>
        <Typography variant="h6" className={styles.Title}>
          {currentPageTitle}
        </Typography>
        <Typography variant="subtitle1" className={styles.subtitle}>
          {localStorage
            .getItem("Email")
            .substring(0, localStorage.getItem("Email").indexOf("@"))}
        </Typography>
      </Box>
    </>
  );
}

Menu.propTypes = propTypes;

export default Menu;
