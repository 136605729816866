import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";

import Loader from "../../../components/Loader";
import apiCall from "../../../services/apiCall";

import styles from "./styles.module.scss";

function AddRetadores() {
  let history = useHistory();
  const [data, setData] = useState({});
  const [supervisores, setSupervisores] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tipos, setTipos] = useState([]);
  const [alert, setAlert] = useState({ isOpen: false, message: "" });
  const location = useLocation();

  useEffect(() => {
    setData(location.state.detail);
  }, [location]);

  async function getData(url, verb, payload) {
    setIsLoading(true);
    const response = await apiCall(url, verb, payload);
    setIsLoading(false);

    if (response instanceof Error) {
      setAlert({ isOpen: true, message: response.message });
      return null;
    } else {
      setAlert({ isOpen: false, message: "" });
      return response;
    }
  }

  async function makeFilters() {
    const Email = localStorage.getItem("Email");
    const IdReto = location.state.detail.ID.substring(11);
    const url = `/prod/reto21/evaluated-for-reto21?superior=${Email}&fecha=${location.state.detail.fecha}&ID=${IdReto}`;

    const tipos = await getData(url);

    if (tipos) {
      setTipos(tipos);
    }
    return null;
  }

  useEffect(() => {
    makeFilters();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    const Email = localStorage.getItem("Email");

    const test = {
      ID: data.ID,
      superior: Email,
      retadores: supervisores,
    };

    console.log(test);
    const result = await apiCall(`/prod/reto21/add-retadores`, "post", test);
    setIsLoading(false);

    if (result.statusCode === 200) {
      history.push("/reto21/mis-retos21");
    } else {
      console.log(result);
    }
    return null;
  }

  ////////////////RENDER//////////////

  function renderSupervisores() {
    if (tipos == null) {
      return null;
    }
    const mappedTipos = tipos.map((tip) => {
      return { value: tip.ID, label: tip.nombre + " " + tip.apellido };
    });

    const animatedComponents2 = makeAnimated();

    return (
      <div className={styles.middle}>
        <label for="tipo" className={styles.Label}>
          Retadores para Agregar*
        </label>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponents2}
          isMulti
          options={mappedTipos}
          onChange={setSupervisores}
        />
      </div>
    );
  }

  return (
    <Container maxWidth="xs" className={styles.PaddingUp}>
      <Loader isLoading={isLoading} />
      <form onSubmit={handleSubmit} className={styles.form}>
        <Typography variant="h6" className={styles.title}>
          Agregar Retadores al Reto 21 del {data.fecha}
        </Typography>
        {renderSupervisores()}
        <Typography className={styles.title}></Typography>
        <button type="submit" className={styles.button}>
          AGREGAR RETADORES
        </button>
        <Typography variant="subtittle" className={styles.EndTitle}>
          Recuerda que solo aparecerán evaluaciones efectuadas con fecha igual o
          superior a la de inicio del reto21 y que sean clientes.
        </Typography>
      </form>
    </Container>
  );
}

export default AddRetadores;
