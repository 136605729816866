import Routes from "../src/routes/Routes";

import { StylesProvider } from "@material-ui/styles";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";

const defaultTheme = createMuiTheme();
const theme = createMuiTheme({
  overrides: {
    MUIDataTable: {
      responsiveScrollMaxHeight: {
        maxHeight: "80vh!important",
      },
    },

    MuiPickersCalendarHeader: {
      iconButton: {
        color: "#ff3c2e",
      },
    },

    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#ff3c2e",
        "&:hover": {
          backgroundColor: "#ff3c2e",
        },
      },
      current: {
        color: "#ff3c2e",
      },
    },

    MuiInputLabel: {
      root: {
        // background: "#2d2b2a",
        // borderRadius: "20px",
        // padding: "2px 8px",
        fontSize: "0.9rem",
      },
      outlined: {
        "&$shrink": {
          transform: "translate(14px, -16px) scale(0.8)",
          background: "#2d2b2a",
          borderRadius: "20px",
          padding: "2px 8px",
          minWidth: "120px",
        },
      },
    },

    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#F4F4F4",
      },
      root: {
        borderRadius: "0 0 15px 15px",
        marginBottom: "20px",
      },
    },

    MUIDataTableBodyCell: {
      root: {
        backgroundColor: "#FFF", //color en row
        fontSize: "0.86rem",
      },
    },

    MUIDataTableFilter: {
      root: {
        minWidth: "300px",
      },
    },

    MuiToolbar: {
      root: {
        color: "#FFF",
        justifyContent: "space-between",
      },
    },

    MuiIconButton: {
      root: {
        color: "#FFF",
      },
    },

    MuiBox: {
      root: {
        paddingLeft: "20px",
        paddingRight: "20px",
      },
    },

    // MuiInputBase: {
    //   root: {
    //     color: "#FFF",
    //   },
    // },

    MuiInputBase: {
      root: {
        color: "#FFF",
        minWidth: "133px",
        height: "52px",
        marginRight: "5px",
      },

      formControl: {
        [defaultTheme.breakpoints.down("sm")]: {
          height: "33px",
          width: "130px",
          marginLeft: "10px",
          marginTop: "4px",
        },
      },
    },

    MuiAccordion: {
      root: {
        backgroundColor: "#FFF",
        color: "#2D2B2A",
      },
    },

    MuiCheckbox: {
      root: {
        color: "white",
      },
    },

    MuiPaper: {
      elevation0: {
        height: "35vh",
      },
    },
  },

  palette: {
    primary: {
      main: "#ff3c2e",
    },
    secondary: {
      main: "#ffffff",
    },
    expand: {
      main: "#000",
    },
  },
  MuiCheckbox: {
    color: "white",
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <StylesProvider>
        <Routes />
      </StylesProvider>
    </MuiThemeProvider>
  );
}

export default App;
