import { useState, useEffect, memo, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";

import apiCall from "../../../services/apiCall";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Alert from "../../../components/Alert";
import Loader from "../../../components/Loader";
import TableUI from "../../../components/TablesUI/TableUI";
import DynamicFilter from "../../../components/DynamicFilter";

import styles from "./styles.module.scss";
import { Typography } from "@material-ui/core";

import avatar from "../../../assets/images/avatar.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function UpdateEquipo() {
  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ///////// PRIVATE METHODS ////////
  //  //////////////////////////////////
  //  //////////////////////////////////
  const MySwal = withReactContent(Swal);
  let history = useHistory();
  const [data, setData] = useState({});
  const [equipo, setEquipo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ isOpen: false, message: "" });
  const [filters, setFilters] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const location = useLocation();

  const [team, setTeam] = useState({
    1: "0",
    2: "0",
    3: "0",
    4: "0",
    5: "0",
    6: "0",
    7: "0",
    8: "0",
    9: "0",
  });

  async function getData(url) {
    setIsLoading(true);
    const response = await apiCall(url);
    setIsLoading(false);

    if (response instanceof Error) {
      setAlert({ isOpen: true, message: response.message });
      return null;
    } else {
      setAlert({ isOpen: false, message: "" });
      return response;
    }
  }

  useEffect(() => {
    getFilters();
  }, []);

  function getDateFilter(filters, discriminator) {
    const dateFilter = filters.find((filter) => filter.key === discriminator);

    return dateFilter;
  }

  async function getFilters() {
    const email = localStorage.getItem("Email");
    const filters = await getData(
      `/prod/maraton/filter-equipo?ID=${location.state.detail.ID}&email=${email}`
    );

    if (filters) {
      setFilters(filters);
      const email = localStorage.getItem("Email");

      const dateFilter = getDateFilter(filters, "fecha-de-entrega");
      const date = dateFilter?.options[0].value;

      const dateFilterE = getDateFilter(filters, "equipo");
      const grupo = dateFilterE?.options[0].value;

      const appliedFilters = [
        {
          key: "fecha-de-entrega",
          type: "select",
          value: date,
        },
        {
          key: "equipo",
          type: "select",
          value: grupo,
        },
      ];

      setAppliedFilters(appliedFilters);

      localStorage.setItem(appliedFilters[0].key, appliedFilters[0].value);
      localStorage.setItem(appliedFilters[1].key, appliedFilters[1].value);

      const url = `/prod/maraton/get-data-equipo?ID=${location.state.detail.ID}&dia=${date}&equipo=${grupo}&email=${email}`;

      const equipo = await getData(url);

      if (equipo) {
        setEquipo(equipo);
      }
    }
  }

  useEffect(() => {
    setData(location.state.detail);
  }, [location]);

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  //////// EVENT HANDLERS //////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  async function handleChangeFilters(filters) {
    setAppliedFilters(filters);
    console.log(filters);
    const dia = "";
    const grupo = "";
    const email = localStorage.getItem("Email");
    localStorage.setItem(filters[0].key, filters[0].value);
    localStorage.setItem(filters[1].key, filters[1].value);

    if (filters[0].key == "equipo") {
      const url = `/prod/maraton/get-data-equipo?ID=${data.ID}&dia=${filters[1].value}&equipo=${filters[0].value}&email=${email}`;

      const equipo = await getData(url);

      if (equipo) {
        setEquipo(equipo);
      }
    } else {
      const url = `/prod/maraton/get-data-equipo?ID=${data.ID}&dia=${filters[0].value}&equipo=${filters[1].value}&email=${email}`;

      const equipo = await getData(url);

      if (equipo) {
        setEquipo(equipo);
      }
    }
  }

  function handleChangeTeam(event, args) {
    let { name, value } = event.target;

    setTeam({
      ...team,
      [name]: value,
    });
  }

  function handleCloseAlert() {
    setAlert({ isOpen: false, message: "" });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    const Email = localStorage.getItem("Email");

    const test = {
      ID: data.ID,
      superior: Email,
      grupo: localStorage.getItem("equipo"),
      dia: localStorage.getItem("fecha-de-entrega"),
      dataTeam: team,
    };

    console.log(test);
    const result = await apiCall(`/prod/maraton/update-equipo`, "post", test);
    setIsLoading(false);

    if (result.statusCode == 200) {
      MySwal.fire({
        title: `Datos Ingresados`,
        showConfirmButton: true,
        icon: "success",
      }).then(async (result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } else if (result.statusCode == 404) {
      MySwal.fire("Fuera de Horario!", "", "info");
    } else {
      setAlert({ isOpen: true, message: "Fallo al actualizar" });
      console.log(result);
      return null;
    }
    return null;
  }

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ////////// RENDERERS /////////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function renderFilters() {
    if (filters == null) {
      return null;
    }

    return (
      <DynamicFilter
        isLoading={isLoading}
        filters={filters}
        appliedFilters={appliedFilters}
        onAppliedFiltersChange={handleChangeFilters}
        showApplyFiltersButton={false}
      />
    );
  }

  function renderPlayer() {
    if (equipo.length == 0) {
      return null;
    }

    const mappedTipos = equipo.map((tip) => {
      return (
        <div
          key={tip.nombre + tip.peso + tip.order}
          className={styles.MiddleData}
        >
          <label htmlFor="name" className={styles.LabelInput}>
            {tip.nombre}*
          </label>
          <input
            type="text"
            className={styles.Input}
            name={tip.order}
            required
            autoComplete="off"
            placeholder={tip.peso + " kg."}
            onChange={handleChangeTeam}
          />
        </div>
      );
    });

    return <div className={styles.Flex}>{mappedTipos}</div>;
  }

  function renderButton() {
    if (equipo.length == 0) {
      return null;
    }

    var result = 0;
    for (var i = 0; i < equipo.length; ++i) {
      result += Number(equipo[i].peso);
    }

    console.log(result);
    if (result === 0) {
      return (
        <button type="submit" className={styles.Button}>
          INGRESAR
        </button>
      );
    } else {
      return null;
    }
  }

  function renderButtonReset() {
    if (equipo.length == 0) {
      return null;
    }

    var result = 0;
    for (var i = 0; i < equipo.length; ++i) {
      result += Number(equipo[i].peso);
    }

    console.log(result);
    if (result === 0) {
      return null;
    } else {
      return (
        <button type="submit" className={styles.Button}>
          RESETEAR PESOS
        </button>
      );
    }
  }

  return (
    <Container maxWidth="lg" disableGutters>
      <Loader isLoading={isLoading} />
      {renderFilters()}
      <Alert
        isOpen={alert.isOpen}
        message={alert.message}
        onClose={handleCloseAlert}
      />
      <form onSubmit={handleSubmit} className={styles.form}>
        <Typography variant="h6" className={styles.Title}>
          Ingresar Registros de Peso - Maratón {data.fecha}
        </Typography>
        {renderPlayer()}
        {renderButton()}
      </form>

      <form onSubmit={handleSubmit} className={styles.form}>
        {renderButtonReset()}
      </form>
    </Container>
  );
}

export default UpdateEquipo;
