import PropTypes from "prop-types";

import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { customDateStyles, customSelectStyles } from "../Customs/customStyles";

const propTypes = {
  filter: PropTypes.object.isRequired,
  appliedFilter: PropTypes.object,
  onFilterChange: PropTypes.func.isRequired,
};

function CustomDynamicFiltersDatePicker({
  filter,
  appliedFilter,
  onFilterChange,
}) {
  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ///////// PRIVATE METHODS ////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  const config = filter.config;

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  //////// EVENT HANDLERS //////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function handleDateChange(date) {
    // const formattedDate = dateInstance.format(date, "dd/MM/yyyy");
    onFilterChange(date);
  }

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ////////// RENDERERS /////////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function renderFilterDate() {
    const CustomMuiPicker = customSelectStyles(KeyboardDatePicker, config);
    const CustomDate = customDateStyles(CustomMuiPicker);

    let value;
    if (appliedFilter != null) {
      value = appliedFilter.value;
    } else {
      value = new Date();
    }

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <CustomDate
          disableToolbar
          format="dd/MM/yyyy"
          value={value}
          onChange={handleDateChange}
          autoOk={true}
          margin="normal"
          label={filter.label}
          variant="inline"
          inputVariant="outlined"
        />
      </MuiPickersUtilsProvider>
    );
  }

  return renderFilterDate();
}

CustomDynamicFiltersDatePicker.propTypes = propTypes;
export default CustomDynamicFiltersDatePicker;
