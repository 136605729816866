import { useState, useEffect, memo, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Alert from "../../../components/Alert";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Loader from "../../../components/Loader";
import TableUI from "../../../components/TablesUI";
import apiCall from "../../../services/apiCall";
import MaterialIcon from "material-icons-react";
import styles from "./styles.module.scss";

function MisRetos21() {
  const MySwal = withReactContent(Swal);
  let history = useHistory();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ isOpen: false, message: "" });

  const memoizedTable = useMemo(() => renderTable(data.table), [data.table]);

  async function getdataTable(url) {
    const data = await getData(url);

    if (data) {
      setData(data);
    }
  }

  async function getData(url, verb, payload) {
    setIsLoading(true);
    const response = await apiCall(url, verb, payload);
    setIsLoading(false);

    if (response instanceof Error) {
      setAlert({ isOpen: true, message: response.message });
      return null;
    } else {
      setAlert({ isOpen: false, message: "" });
      return response;
    }
  }

  async function initData() {
    const email = localStorage.getItem("Email");
    const url = `/prod/reto21/show-all-retos-email?email=${email}`;

    getdataTable(url);
  }

  useEffect(() => {
    initData();
  }, []);

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  //////// EVENT HANDLERS //////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function handleCloseAlert() {
    setAlert({ isOpen: false, message: "" });
  }

  async function handleClickRet(row) {
    history.push({
      pathname: "/reto21/add-retadores",
      state: { detail: row },
    });
  }

  async function handleClickShow(row) {
    history.push({
      pathname: "/reto21/ver-competencia",
      state: { detail: row },
    });
  }
  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ////////// RENDERERS /////////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function renderTable(table) {
    if (table == null) {
      return null;
    }
    const columns = [
      {
        name: "Agregar Retadores",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            if (tableMeta.tableData[tableMeta.rowIndex]["estado"] == "Activo") {
              return (
                <button
                  style={{ all: "unset", cursor: "pointer" }}
                  onClick={() => {
                    handleClickRet(tableMeta.tableData[tableMeta.rowIndex]);
                  }}
                >
                  <MaterialIcon
                    icon="supervisor_account"
                    size="medium"
                    color="#1C04E5"
                  />
                </button>
              );
            } else {
              return (
                <p style={{ color: "#F30517", fontFamily: "fantasy" }}>
                  Reto21 Cerrado
                </p>
              );
            }
          },
        },
      },
      {
        name: "Ver Competencia",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            if (
              tableMeta.tableData[tableMeta.rowIndex]["estado"] == "Inactivo"
            ) {
              return (
                <button
                  style={{ all: "unset", cursor: "pointer" }}
                  onClick={() => {
                    handleClickShow(tableMeta.tableData[tableMeta.rowIndex]);
                  }}
                >
                  <MaterialIcon
                    icon="emoji_events"
                    size="medium"
                    color="#229954"
                  />
                </button>
              );
            } else {
              return (
                <p style={{ color: "#10A958", fontFamily: "fantasy" }}>
                  Reto21 en Proceso
                </p>
              );
            }
          },
        },
      },
      {
        name: "fecha",
        label: "Fecha",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "estado",
        label: "Estado",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "retadores",
        label: "Retadores",
        options: {
          filter: true,
          sort: true,
        },
      },
    ];

    const { data, config } = table;

    return <TableUI data={data} columns={columns} config={config} />;
  }

  return (
    <Container maxWidth="xl" disableGutters>
      <Loader isLoading={isLoading} />
      <Alert
        isOpen={alert.isOpen}
        message={alert.message}
        onClose={handleCloseAlert}
      />
      {memoizedTable}
    </Container>
  );
}

export default memo(MisRetos21);
