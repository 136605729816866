import { useState, useEffect, memo, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Alert from "../../../components/Alert";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Loader from "../../../components/Loader";
import TableUI from "../../../components/TablesUI";
import apiCall from "../../../services/apiCall";
import MaterialIcon from "material-icons-react";
import styles from "./styles.module.scss";
import { Typography } from "@material-ui/core";

function VerCompetenciaPre() {
  const MySwal = withReactContent(Swal);
  let history = useHistory();
  const [data, setData] = useState({});
  const [reto, setReto] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ isOpen: false, message: "" });
  const location = useLocation();
  const memoizedTable = useMemo(() => renderTable(data.table), [data.table]);

  async function getData(url, verb, payload) {
    setIsLoading(true);
    const response = await apiCall(url, verb, payload);
    setIsLoading(false);

    if (response instanceof Error) {
      setAlert({ isOpen: true, message: response.message });
      return null;
    } else {
      setAlert({ isOpen: false, message: "" });
      return response;
    }
  }

  async function initData() {
    const url = `/prod/reto21/rel-preliminar?reto=${location.state.Reto.ID}`;

    const data = await getData(url);

    if (data) {
      setData(data);
    }
  }

  useEffect(() => {
    initData();
  }, []);

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  //////// EVENT HANDLERS //////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function handleCloseAlert() {
    setAlert({ isOpen: false, message: "" });
  }

  //  //////////////////////////////////
  //  //////////////////////////////////
  //  ////////// RENDERERS /////////////
  //  //////////////////////////////////
  //  //////////////////////////////////

  function renderTable(table) {
    if (table == null) {
      return null;
    }
    const columns = [
      {
        name: "nombre",
        label: "Nombre",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "pesoini",
        label: "KG. I",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "pesofin",
        label: "KG. F",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "diferenciapeso",
        label: "Dif. KG.",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "imcini",
        label: "IMC I",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "imcfin",
        label: "IMC F",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "diferenciaimc",
        label: "Dif. IMC",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "grasaini",
        label: "%G. I",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "grasafin",
        label: "%G. F",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "difgrasa",
        label: "Dif. %G.",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "ciudad",
        label: "Ciudad",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "coach",
        label: "Coach",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "canteval",
        label: "Num Eval.",
        options: {
          filter: true,
          sort: true,
        },
      },
    ];

    const { data, config } = table;

    return <TableUI data={data} columns={columns} config={config} />;
  }

  function renderKilos() {
    if (data == null) {
      return null;
    }

    const { kilos } = data;

    if (kilos == null) {
      return null;
    }

    return (
      <div className={styles.Cuadros}>
        <Typography
          className={styles.TittleKilos}
          style={{
            fontFamily: "Facon",
            fontSize: "20px",
            textAlign: "center",
            paddingTop: "5px",
          }}
        >
          Ganad@r categoría KILOS: <b>{kilos[0]["nombre"]}</b>
        </Typography>
        <div>
          <p className={styles.ResultadosKilos}>
            <MaterialIcon icon="looks_one" size="small" color="#4766F0" />
            {kilos[0]["nombre"]} - <b>{kilos[0]["kilos"] + " Kilos"}</b>
          </p>
          <p className={styles.ResultadosKilos}>
            <MaterialIcon icon="looks_two" size="small" color="#4766F0" />
            {kilos[1]["nombre"]} - <b>{kilos[1]["kilos"] + " Kilos"}</b>
          </p>
          <p className={styles.ResultadosKilos}>
            <MaterialIcon icon="looks_3" size="small" color="#4766F0" />
            {kilos[2]["nombre"]} - <b>{kilos[2]["kilos"] + " Kilos"}</b>
          </p>
        </div>
      </div>
    );
  }

  function renderIMC() {
    if (data == null) {
      return null;
    }

    const { imc } = data;

    if (imc == null) {
      return null;
    }
    return (
      <div className={styles.Cuadros}>
        <Typography
          className={styles.TittleIMC}
          style={{
            fontFamily: "Facon",
            fontSize: "20px",
            textAlign: "center",
            paddingTop: "5px",
          }}
        >
          Ganad@r categoría IMC: <b>{imc[0]["nombre"]}</b>
        </Typography>
        <div>
          <p className={styles.ResultadosKilos}>
            <MaterialIcon icon="looks_one" size="small" color="#C78C05" />
            {imc[0]["nombre"]} - <b>{imc[0]["imc"]} IMC</b>
          </p>
          <p className={styles.ResultadosKilos}>
            <MaterialIcon icon="looks_two" size="small" color="#C78C05" />
            {imc[1]["nombre"]} - <b>{imc[1]["imc"]} IMC</b>
          </p>
          <p className={styles.ResultadosKilos}>
            <MaterialIcon icon="looks_3" size="small" color="#C78C05" />
            {imc[2]["nombre"]} - <b>{imc[2]["imc"]} IMC</b>
          </p>
        </div>
      </div>
    );
  }

  function renderGrasa() {
    if (data == null) {
      return null;
    }

    const { grasa } = data;

    if (grasa == null) {
      return null;
    }
    return (
      <div className={styles.Cuadros}>
        <Typography
          className={styles.TittleGrasa}
          style={{
            fontFamily: "Facon",
            fontSize: "20px",
            textAlign: "center",
            paddingTop: "5px",
          }}
        >
          Ganad@r categoría % GRASA: <b>{grasa[0]["nombre"]}</b>
        </Typography>
        <div>
          <p className={styles.ResultadosKilos}>
            <MaterialIcon icon="looks_one" size="small" color="#06BB5B" />
            {grasa[0]["nombre"]} - <b>{grasa[0]["grasa"]} % Grasa</b>
          </p>
          <p className={styles.ResultadosKilos}>
            <MaterialIcon icon="looks_two" size="small" color="#06BB5B" />
            {grasa[1]["nombre"]} - <b>{grasa[1]["grasa"]} % Grasa</b>
          </p>
          <p className={styles.ResultadosKilos}>
            <MaterialIcon icon="looks_3" size="small" color="#06BB5B" />
            {grasa[2]["nombre"]} - <b>{grasa[2]["grasa"]} % Grasa</b>
          </p>
        </div>
      </div>
    );
  }
  return (
    <Container maxWidth="lg" className={styles.PaddingUp}>
      <Loader isLoading={isLoading} />
      <Alert
        isOpen={alert.isOpen}
        message={alert.message}
        onClose={handleCloseAlert}
      />
      <div className={styles.form}>
        <Typography
          variant="h4"
          className={styles.Title}
          style={{
            fontFamily: "Marvel",
            fontSize: "70px",
            textAlign: "center",
            paddingTop: "50px",
          }}
        >
          Resultados Preliminares {location.state.Reto.fecha}
        </Typography>
        {renderKilos()}
        {renderIMC()}
        {renderGrasa()}
        {memoizedTable}
      </div>
    </Container>
  );
}

export default memo(VerCompetenciaPre);
