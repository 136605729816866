import { Switch, Route } from "react-router-dom";
import { isEmpty } from "lodash";

import ProtectedRoute from "./ProtectedRoute";
import Menu from "../components/Menu/container/Menu";

import { ROLES } from "./ROLES";

import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import HomeApp from "../pages/HomeApp";
import AfterLogin from "../pages/AfterLogin";
import NewEvaluation from "../pages/Evaluation/NewEvaluation";
import ShowAllEv from "../pages/Evaluation/ShowAllEv";
import OneEvaluationMake from "../pages/Evaluation/OneEvaluationMake";
import Seguimiento from "../pages/Evaluation/Seguimiento";
import NewUser from "../pages/Users/NewUser/NewUser";
import ShowAllUsers from "../pages/Users/ShowAllUsers";
import None from "../pages/None";

import NewReto21 from "../pages/Reto21/NewReto21/NewReto21";
import ShowAllReto21 from "../pages/Reto21/ShowAllReto21/ShowAllReto21";
import AddSupervisor from "../pages/Reto21/AddSupervisor/AddSupervisor";
import MisRetos21 from "../pages/Reto21/MisRetos21/MisRetos21";
import AddRetadores from "../pages/Reto21/AddRetadores/AddRetadores";
import EditarEv from "../pages/Evaluation/EditarEv/EditarEv";
import VerCompetencia from "../pages/Reto21/VerCompetencia/VerCompetencia";
import NewMaraton from "../pages/Maraton/NewMaraton/NewMaraton";
import AddCapitanes from "../pages/Maraton/AddCapitanes/AddCapitanes";
import ShowAllMaraton from "../pages/Maraton/ShowAllMaraton/ShowAllMaraton";
import MisMaraton from "../pages/Maraton/MisMaraton/MisMaraton";
import AddMaratonistas from "../pages/Maraton/AddMaratonistas/AddMaratonistas";
import UpdateEquipo from "../pages/Maraton/UpdateEquipo/UpdateEquipo";
import VerCompetenciaPre from "../pages/Reto21/VerCompetenciaPre/VerCompetenciaPre";
import CountEv from "../pages/Evaluation/countEv/countEv";
import History from "../pages/Evaluation/History/History";
//last One
//Important

function Routes() {
  function renderNotFoundPage() {
    const isAuthenticated = true;

    if (isEmpty(isAuthenticated)) {
      return (
        <Route>
          <Login />
        </Route>
      );
    }

    return (
      <Route>
        <Menu />
        <NotFound />
      </Route>
    );
  }
  return (
    <Switch>
      {/* ROUTES WITHOUT MENU */}
      <Route exact path="/">
        <Login />
      </Route>
      <Route exact path="/afterlogin">
        <AfterLogin />
      </Route>
      <Route exact path="/none">
        <None />
      </Route>
      {/* ROUTES WITH MENU */}
      <ProtectedRoute
        exact
        path="/home-app"
        requiredRoles={[
          ROLES.ADMIN,
          ROLES.AMBOS,
          ROLES.SUPERVISOR,
          ROLES.CAPITAN,
        ]}
      >
        <Menu />
        <HomeApp />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/evaluation/new-evaluation"
        requiredRoles={[
          ROLES.ADMIN,
          ROLES.AMBOS,
          ROLES.SUPERVISOR,
          ROLES.CAPITAN,
        ]}
      >
        <Menu />
        <NewEvaluation />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/evaluation/show-all"
        requiredRoles={[
          ROLES.ADMIN,
          ROLES.AMBOS,
          ROLES.SUPERVISOR,
          ROLES.CAPITAN,
        ]}
      >
        <Menu />
        <ShowAllEv />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/evaluation/one-evaluation"
        requiredRoles={[
          ROLES.ADMIN,
          ROLES.AMBOS,
          ROLES.SUPERVISOR,
          ROLES.CAPITAN,
        ]}
      >
        <Menu />
        <OneEvaluationMake />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/evaluation/seguimiento"
        requiredRoles={[
          ROLES.ADMIN,
          ROLES.AMBOS,
          ROLES.SUPERVISOR,
          ROLES.CAPITAN,
        ]}
      >
        <Menu />
        <Seguimiento />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/evaluation/edit"
        requiredRoles={[
          ROLES.ADMIN,
          ROLES.AMBOS,
          ROLES.SUPERVISOR,
          ROLES.CAPITAN,
        ]}
      >
        <Menu />
        <EditarEv />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/evaluation/count"
        requiredRoles={[ROLES.ADMIN]}
      >
        <Menu />
        <CountEv />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/evaluation/hist"
        requiredRoles={[ROLES.ADMIN, ROLES.AMBOS]}
      >
        <Menu />
        <History />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/users/show-all"
        requiredRoles={[ROLES.ADMIN]}
      >
        <Menu />
        <ShowAllUsers />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/users/new-users"
        requiredRoles={[ROLES.ADMIN]}
      >
        <Menu />
        <NewUser />
      </ProtectedRoute>
      <ProtectedRoute exact path="/reto21/new" requiredRoles={[ROLES.ADMIN]}>
        <Menu />
        <NewReto21 />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/reto21/show-all"
        requiredRoles={[ROLES.ADMIN]}
      >
        <Menu />
        <ShowAllReto21 />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/reto21/add-supervisores"
        requiredRoles={[ROLES.ADMIN]}
      >
        <Menu />
        <AddSupervisor />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/reto21/mis-retos21"
        requiredRoles={[ROLES.ADMIN, ROLES.AMBOS, ROLES.SUPERVISOR]}
      >
        <Menu />
        <MisRetos21 />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/reto21/add-retadores"
        requiredRoles={[ROLES.ADMIN, ROLES.AMBOS, ROLES.SUPERVISOR]}
      >
        <Menu />
        <AddRetadores />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/reto21/ver-competencia"
        requiredRoles={[ROLES.ADMIN, ROLES.AMBOS, ROLES.SUPERVISOR]}
      >
        <Menu />
        <VerCompetencia />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/reto21/ver-competencia-preliminar"
        requiredRoles={[ROLES.ADMIN, ROLES.AMBOS, ROLES.SUPERVISOR]}
      >
        <Menu />
        <VerCompetenciaPre />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/maraton/new-maraton"
        requiredRoles={[ROLES.ADMIN]}
      >
        <Menu />
        <NewMaraton />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/maraton/add-capitanes"
        requiredRoles={[ROLES.ADMIN]}
      >
        <Menu />
        <AddCapitanes />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/maraton/show-all"
        requiredRoles={[ROLES.ADMIN]}
      >
        <Menu />
        <ShowAllMaraton />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/maraton/mis-maraton"
        requiredRoles={[ROLES.ADMIN, ROLES.AMBOS, ROLES.CAPITAN]}
      >
        <Menu />
        <MisMaraton />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/maraton/add-maratonistas"
        requiredRoles={[ROLES.ADMIN, ROLES.AMBOS, ROLES.CAPITAN]}
      >
        <Menu />
        <AddMaratonistas />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/maraton/ver-competencia"
        requiredRoles={[ROLES.ADMIN, ROLES.AMBOS, ROLES.CAPITAN]}
      >
        <Menu />
        <UpdateEquipo />
      </ProtectedRoute>
      {renderNotFoundPage()}
    </Switch>
  );
}

export default Routes;
