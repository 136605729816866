import LocalShippingOutlinedIcon from "@material-ui/icons/LocalShippingOutlined";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import Flecha from "../../assets/images/menuIcons/Flecha.svg";
import CrearProceso from "../../assets/images/menuIcons/CrearProceso.svg";
import Equipos from "../../assets/images/menuIcons/Equipos.svg";
import Evaluacion from "../../assets/images/menuIcons/Evaluacion.svg";
import Home from "../../assets/images/menuIcons/Home.svg";
import Result from "../../assets/images/menuIcons/Result.svg";
import Two from "../../assets/images/menuIcons/Two.svg";
import Process from "../../assets/images/menuIcons/Process.svg";
import Process2 from "../../assets/images/menuIcons/Process2.svg";

function iconFactory() {
  function renderIcon(icon) {
    return <img src={icon} alt="icon" style={{ width: "45px" }} />;
  }

  return {
    Flecha: renderIcon(Flecha),
    CrearProceso: renderIcon(CrearProceso),
    Equipos: renderIcon(Equipos),
    Evaluacion: renderIcon(Evaluacion),
    Home: renderIcon(Home),
    Result: renderIcon(Result),
    Two: renderIcon(Two),
    Process: renderIcon(Process),
    Process2: renderIcon(Process2),
  };
}
export default iconFactory;
