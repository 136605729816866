import Chart from "react-apexcharts";
import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Container from "@material-ui/core/Container";

import Alert from "../../../components/Alert";
import Loader from "../../../components/Loader";
import apiCall from "../../../services/apiCall";

import styles from "./styles.module.scss";
import { Typography } from "@material-ui/core";

function RenderChart() {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ isOpen: false, message: "" });
  const [data, setData] = useState();
  const location = useLocation();

  async function getdataTable(url) {
    const data = await getData(url);

    if (data) {
      setData(data);
    }
  }

  async function getData(url, verb, payload) {
    setIsLoading(true);
    const response = await apiCall(url, verb, payload);
    setIsLoading(false);

    if (response instanceof Error) {
      setAlert({ isOpen: true, message: response.message });
      return null;
    } else {
      setAlert({ isOpen: false, message: "" });
      return response;
    }
  }

  async function initData() {
    const superior = localStorage.getItem("Email");
    const url = `/prod/evaluation/chart?ID=${location.state.detail.ID}&superior=${superior}`;

    getdataTable(url);
  }

  useEffect(() => {
    initData();
  }, []);

  function handleCloseAlert() {
    setAlert({ isOpen: false, message: "" });
  }

  const config = {
    options: {
      title: {
        text: "Grafico de IMC y Peso en el tiempo",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [0],
      },
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 4],
      },
      yaxis: [
        {
          opposite: false,
          min: 50
        },
        {
          opposite: true,
          min: 15,
          max: 40,
        },
      ],
    },
  };

  const optionSecond = {
    title: {
      text: "Grafico % de grasa en el tiempo",
    },
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0],
    },
  };

  function renderCharts(data, config) {
    if (data == null) {
      return null;
    }

    config["options"].xaxis = data["xaxis"];
    optionSecond.xaxis = data["xaxis"];
    console.log(config["options"]);
    return (
      <div>
        <Chart
          options={config["options"]}
          series={data["series"]}
          width="100%"
          height={320}
        />
        <Chart
          options={optionSecond}
          series={data["series2"]}
          width="100%"
          height={320}
        />
      </div>
    );
  }

  return (
    <Container maxWidth="lg" className={styles.PaddingUp}>
      <Loader isLoading={isLoading} />
      <Alert
        isOpen={alert.isOpen}
        message={alert.message}
        onClose={handleCloseAlert}
      />
      <Typography style={{ "text-align": "center" }}>
        <b>Nombre: </b>
        {location.state.detail.nombre}
      </Typography>
      {renderCharts(data, config)}
    </Container>
  );
}

export default RenderChart;
