import { useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import { customInputStyles } from "../Customs/customStyles";

function Text({ filter, appliedFilter, onFilterChange }) {
  const config = filter.config;

  const MemoizedTextField = useMemo(
    () => customInputStyles(TextField, config),
    [TextField, config]
  );

  function handleChange(event) {
    const { value } = event.target;

    if (value) {
      onFilterChange(value);
    } else {
      onFilterChange(null);
    }
  }

  function renderText() {
    let value;

    if (appliedFilter != null) {
      value = appliedFilter.value;
    } else {
      value = "";
    }

    return (
      <MemoizedTextField
        label={filter.label}
        onChange={handleChange}
        value={value}
        variant="outlined"
        multiline={config.multiline ?? false}
      />
    );
  }

  return renderText();
}

export default Text;
