import { useState, useEffect, memo, useMemo } from "react";
import { useHistory } from "react-router-dom";

import MaterialIcon from "material-icons-react";
import Container from "@material-ui/core/Container";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Alert from "../../../components/Alert";
import Loader from "../../../components/Loader";
import TableUI from "../../../components/TablesUI";
import apiCall from "../../../services/apiCall";

import styles from "./styles.module.scss";

function ShowAllEv() {
  const MySwal = withReactContent(Swal);
  let history = useHistory();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ isOpen: false, message: "" });

  const memoizedTable = useMemo(() => renderTable(data.table), [data.table]);

  async function getdataTable(url) {
    const data = await getData(url);

    if (data) {
      setData(data);
    }
  }

  async function getData(url, verb, payload) {
    setIsLoading(true);
    const response = await apiCall(url, verb, payload);
    setIsLoading(false);

    if (response instanceof Error) {
      setAlert({ isOpen: true, message: response.message });
      return null;
    } else {
      setAlert({ isOpen: false, message: "" });
      return response;
    }
  }

  async function initData() {
    const Email = localStorage.getItem("Email");
    const tipe = localStorage.getItem("tipe");

    const url = `/prod/evaluation/show-all?user=${Email}&tipe=${tipe}`;

    getdataTable(url);
  }

  useEffect(() => {
    initData();
  }, []);

  //  //////// EVENT HANDLERS //////////

  function handleCloseAlert() {
    setAlert({ isOpen: false, message: "" });
  }

  async function handleClickDelete(row) {
    const delet = {
      ID: row.ID,
      fecha: row.fecha,
    };

    MySwal.fire({
      title: `Estas seguro de que quieres borrar la evaluacion?`,
      showDenyButton: true,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      denyButtonText: "Eliminar",
      icon: "question",
    }).then(async (result) => {
      if (result.isDenied) {
        const result = await getData(`/prod/evaluation/delete`, "post", delet);

        if (result.statusCode === 200) {
          MySwal.fire("La evaluacion ha sido eliminada!", "", "success");
          initData();
        } else {
          MySwal.fire("La evaluacion no se elimino", "", "info");
        }
      }
    });

    return null;
  }

  function handleClickEv(row) {
    history.push({
      pathname: "/evaluation/one-evaluation",
      state: { detail: row },
    });
  }

  function handleClickHist(row) {
    history.push({
      pathname: "/evaluation/hist",
      state: { detail: row },
    });
  }

  function handleClickSeg(row) {
    history.push({
      pathname: "/evaluation/seguimiento",
      state: { detail: row },
    });
  }

  function handleClickEdit(row) {
    history.push({
      pathname: "/evaluation/edit",
      state: { detail: row },
    });
  }

  function handleClickClient(row) {
    const clientSi = {
      ID: row.ID,
      fecha: row.fecha,
      cliente: "Si",
    };

    const clientNo = {
      ID: row.ID,
      fecha: row.fecha,
      cliente: "No",
    };

    MySwal.fire({
      title: `¿Estas seguro de que es Cliente?`,
      showDenyButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Si lo es",
      cancelButtonText: "Cancelar",
      denyButtonText: "No lo es",
      icon: "question",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await getData(
          `/prod/evaluation/cliente`,
          "post",
          clientSi
        );

        if (result.statusCode === 200) {
          MySwal.fire("Ha quedado como cliente!", "", "success");
          initData();
        } else {
          MySwal.fire("No se pudo dejar como", "", "error");
        }
      } else if (result.isDenied) {
        const result = await getData(
          `/prod/evaluation/cliente`,
          "post",
          clientNo
        );

        if (result.statusCode === 200) {
          MySwal.fire("Ha dejado de ser Cliente", "", "info");
          initData();
        } else {
          MySwal.fire("No se pudo dejar como No cliente", "", "info");
        }
      }
    });
  }
  //  ////////// RENDERERS /////////////

  function renderTable(table) {
    if (table == null) {
      return null;
    }
    const columns = [
      {
        name: "Eliminar",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <button
                style={{ all: "unset", cursor: "pointer" }}
                onClick={() => {
                  handleClickDelete(tableMeta.tableData[tableMeta.rowIndex]);
                }}
                className={styles.ButtonShow}
              >
                <MaterialIcon icon="delete_forever" size="medium" color="red" />
              </button>
            );
          },
        },
      },
      {
        name: "Ver Ev.",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <button
                style={{ all: "unset", cursor: "pointer" }}
                onClick={() => {
                  handleClickEv(tableMeta.tableData[tableMeta.rowIndex]);
                }}
              >
                <MaterialIcon icon="visibility" size="medium" color="#229954" />
              </button>
            );
          },
        },
      },
      {
        name: "Hacer Cliente",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <button
                style={{ all: "unset", cursor: "pointer" }}
                onClick={() => {
                  handleClickClient(tableMeta.tableData[tableMeta.rowIndex]);
                }}
              >
                <MaterialIcon icon="verified" size="medium" color="#ED7348" />
              </button>
            );
          },
        },
      },
      {
        name: "Seguimiento",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <button
                style={{ all: "unset", cursor: "pointer" }}
                onClick={() => {
                  handleClickSeg(tableMeta.tableData[tableMeta.rowIndex]);
                }}
              >
                <MaterialIcon
                  icon="manage_search"
                  size="medium"
                  color="#5B5DDF"
                />
              </button>
            );
          },
        },
      },
      {
        name: "Historal",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <button
                style={{ all: "unset", cursor: "pointer" }}
                onClick={() => {
                  handleClickHist(tableMeta.tableData[tableMeta.rowIndex]);
                }}
              >
                <MaterialIcon icon="history" size="medium" color="#AF1143" />
              </button>
            );
          },
        },
      },
      {
        name: "Editar",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <button
                style={{ all: "unset", cursor: "pointer" }}
                onClick={() => {
                  handleClickEdit(tableMeta.tableData[tableMeta.rowIndex]);
                }}
              >
                <MaterialIcon icon="edit" size="medium" color="#5AAADF" />
              </button>
            );
          },
        },
      },
      {
        name: "fecha",
        label: "Fecha",
        options: {
          filter: true,
          sort: true,
          // sortDirection: "desc",
        },
      },
      {
        name: "nombre",
        label: "Nombre",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "apellido",
        label: "Apellido",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "cliente",
        label: "Cliente",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "ciudad",
        label: "Ciudad",
        options: {
          filter: true,
          sort: true,
          display: false,
        },
      },
      {
        name: "telefono",
        label: "Celular",
        options: {
          filter: true,
          sort: true,
          display: false,
        },
      },
      {
        name: "edad",
        label: "Edad",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
      {
        name: "sexo",
        label: "Sexo",
        options: {
          filter: true,
          sort: true,
          display: false,
        },
      },
      {
        name: "peso",
        label: "Peso kg",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
      {
        name: "altura",
        label: "Altura cm",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
      {
        name: "fechac",
        label: "Fecha Cumpleaños",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
      {
        name: "cmcuello",
        label: "Cuello cm",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
      {
        name: "cmcintura",
        label: "Cintura cm",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
      {
        name: "cmcadera",
        label: "Cadera cm",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
      {
        name: "imc",
        label: "IMC",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
      {
        name: "percentgrasa",
        label: "% de Grasa",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
      {
        name: "referido",
        label: "Referido Por",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
    ];

    const { data, config } = table;

    return <TableUI data={data} columns={columns} config={config} />;
  }

  return (
    <Container maxWidth="xl" disableGutters>
      <Loader isLoading={isLoading} />
      <Alert
        isOpen={alert.isOpen}
        message={alert.message}
        onClose={handleCloseAlert}
      />
      {memoizedTable}
    </Container>
  );
}

export default memo(ShowAllEv);
