import { useState } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";

import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import UserPool from "../../services/UserPool";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert/Alert";

import styles from "./styles.module.scss";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ isOpen: false, message: "" });

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    const url = "https://teamlifes.com";
    // const url = "https://dev.d1lv46gxpqhbh7.amplifyapp.com";
    // const url = "http://localhost:3000";

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    console.log({ email });
    console.log({ password });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log("onSuccess:", data);
        localStorage.setItem("Email", data.idToken.payload.email);
        localStorage.setItem("Token", data.idToken.jwtToken);
        window.location.href = url + "/afterlogin";
      },

      onFailure: (err) => {
        console.error("onFailure:", err);
        setAlert({
          isOpen: true,
          message: "Error: " + err.message,
        });
        setIsLoading(false);
      },

      newPasswordRequired: (data) => {
        console.log("newPasswordRequired:", data);
        localStorage.setItem("Email", data.idToken.payload.email);
        localStorage.setItem("Token", data.idToken.jwtToken);
        window.location.href = url + "/afterlogin";
      },
    });

    return null;
  }

  function handleCloseAlert() {
    setAlert({
      isOpen: false,
      message: "",
    });
  }

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="#">
          www.team-lifes.cl
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  return (
    <Container maxWidth="xs" className={styles.Cuadro}>
      <Alert
        isOpen={alert.isOpen}
        message={alert.message}
        onClose={handleCloseAlert}
      />
      <Loader isLoading={isLoading} />
      <Typography component="h1" variant="h5" className={styles.Title}>
        !Bienvenido a Team Lifes!
      </Typography>
      <form noValidate className={styles.Form} onSubmit={handleSubmit}>
        <label for="name" className={styles.Label}>
          Correo Electrónico:
        </label>
        <input
          type="text"
          id="name"
          name="name"
          required
          className={styles.Input}
          onChange={(event) => setEmail(event.target.value.toLowerCase())}
        />
        <label for="name" className={styles.Label}>
          Contraseña:
        </label>
        <input
          type="password"
          id="password"
          name="password"
          required
          className={styles.Input}
          onChange={(event) => setPassword(event.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={styles.ButtonD}
        >
          Entrar
        </Button>
      </form>
      <div className={styles.Forgot}>
        <div className={styles.Link}>
          <a href="https://teamlifes-mail.auth.us-east-2.amazoncognito.com/signup?client_id=2eg1i31hkcnrr01sjkirvvib3m&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://main.d1lv46gxpqhbh7.amplifyapp.com">
            Registrarse
          </a>
        </div>
        <div className={styles.Link}>
          <a href="https://teamlifes-mail.auth.us-east-2.amazoncognito.com/forgotPassword?client_id=2eg1i31hkcnrr01sjkirvvib3m&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://main.d1lv46gxpqhbh7.amplifyapp.com">
            ¿Olvidaste Contraseña?
          </a>
        </div>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default Login;
